import { statesArray } from '@app/constants/states';
import { Address } from '@app/interfaces/address';
import { getEnvironment } from '@app/utils/environment';
import useScript from '@atob-developers/shared/src/hooks/useScript';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import './BillingAddressTabPanel.css';

const BillingAddressTabPanel = ({
  address,
  cardholderName,
  setAddress,
  setCardholderName,
}: {
  address: Address;
  cardholderName: string;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
  setCardholderName: (cardholderName: string) => void;
}): ReactElement => {
  const { VITE_GOOGLE_API_KEY } = getEnvironment();
  const mapsLoaded =
    useScript(
      `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${VITE_GOOGLE_API_KEY}&v=weekly&callback=Function.prototype`,
    ) === 'ready';
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const address1Ref = useRef<HTMLInputElement>(null);
  const address2Ref = useRef<HTMLInputElement>(null);

  const onChangeAddress = useCallback(
    (value: string, nameOfField: string) => {
      setAddress((prev: Address) => ({
        ...prev,
        [nameOfField]: value,
      }));
    },
    [setAddress],
  );
  const fillInAddress = useCallback(() => {
    if (!autoCompleteRef.current) return;
    const place = autoCompleteRef.current.getPlace();

    if (!place || !place.address_components) return;

    setAddress({
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    });
    let address1 = '';

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.long_name;
          break;
        }

        case 'postal_code': {
          onChangeAddress(component.long_name, 'zip');
          break;
        }

        case 'locality': {
          onChangeAddress(component.long_name, 'city');
          break;
        }

        case 'administrative_area_level_1': {
          onChangeAddress(component.short_name, 'state');
          break;
        }
      }
    }

    onChangeAddress(address1, 'address1');

    address2Ref.current?.focus();
    if (address1Ref.current) {
      address1Ref.current.value = address1;
    }

    autoCompleteRef.current.set('place', undefined);
  }, [setAddress, onChangeAddress]);

  useEffect(() => {
    if (!mapsLoaded || !address1Ref.current || autoCompleteRef.current) {
      return;
    }
    autoCompleteRef.current = new google.maps.places.Autocomplete(address1Ref.current, {
      componentRestrictions: { country: 'us' },
      fields: ['address_components'],
      types: ['address'],
    });
    autoCompleteRef.current.addListener('place_changed', fillInAddress);
  }, [fillInAddress, mapsLoaded]);

  const onChangeCardholderName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardholderName(e.target.value);
  };
  const onChangeAddressField = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAddress(e.target.value, e.target.id);
  };
  const onChangeState = (e: SelectChangeEvent) => {
    onChangeAddress(e.target.value, 'state');
  };

  return (
    <>
      <div className="mt-6 w-full flex-col">
        <div className="w-full">
          <label htmlFor="card-name" className="text-sm font-medium">
            Cardholder name
          </label>
          <input
            className="h-10 w-full"
            id="cardholder-name"
            autoComplete="name"
            placeholder="Bo Darville"
            value={cardholderName}
            onChange={onChangeCardholderName}
          ></input>
        </div>
        <div>
          <label htmlFor="address1" className="text-sm font-medium">
            Address
          </label>
          <input
            className="h-10 w-full"
            id="address1"
            autoComplete="address-line1"
            placeholder="123 Main Street"
            ref={address1Ref}
            onChange={onChangeAddressField}
          ></input>
        </div>
        <div>
          <label htmlFor="address2" className="text-sm font-medium">
            Suite #
          </label>
          <input
            className="h-10 w-full"
            id="address2"
            autoComplete="address-line2"
            placeholder="#400"
            ref={address2Ref}
            value={address.address2 || ''}
            onChange={onChangeAddressField}
          ></input>
        </div>
        <div className="flex">
          <div className="w-3/4 flex-col">
            <label htmlFor="city" className="text-sm font-medium">
              City
            </label>
            <input
              className="h-10 w-[95%]"
              id="city"
              autoComplete="address-level2"
              placeholder="Anytown"
              value={address.city || ''}
              onChange={onChangeAddressField}
            ></input>
          </div>
          <div className="w-1/4 flex-col">
            <label htmlFor="state" className="text-sm font-medium">
              State
            </label>
            <Select
              className="h-10 w-full"
              id="state"
              autoComplete="address-level1"
              placeholder="US"
              value={address.state || ''}
              onChange={onChangeState}
            >
              {statesArray.map((state) => (
                <MenuItem value={state} key={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <label htmlFor="zip" className="text-sm font-medium">
            ZIP
          </label>
          <input
            className="h-10 w-full"
            id="zip"
            autoComplete="postal-code"
            maxLength={5}
            pattern="^\d{5}$"
            placeholder="00000"
            value={address.zip || ''}
            onChange={onChangeAddressField}
          ></input>
        </div>
      </div>
    </>
  );
};

export default BillingAddressTabPanel;
