import RadioSelector from '@app/components/RadioSelector/RadioSelector';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, RadioGroup, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { SetStateAction } from 'react';
import {
  faIconMapping,
  FeeConfiguration,
  feeString,
  filterDestinationsBySelectedMethod,
  iconMapping,
  nameMapping,
  NormalizedDestination,
  PaymentMethodType,
  transferTimeMapping,
} from '../utils';

const PaymentMethodItem = ({
  name,
  selected = false,
  availableMethods,
  temporaryDisabled,
}: {
  name: PaymentMethodType;
  selected?: boolean;
  availableMethods: FeeConfiguration[];
  temporaryDisabled: boolean;
}) => {
  const matchingFee = availableMethods.find((method) => method.type === name);
  const transferTime = transferTimeMapping[name];
  return (
    <div className={classNames('flex items-center gap-2 text-base')}>
      {['zelle', 'venmo', 'paypal'].includes(name) ? (
        <img src={iconMapping[name as 'zelle' | 'paypal' | 'venmo']} alt={name} />
      ) : (
        <FontAwesomeIcon
          icon={faIconMapping[name as 'ach' | 'debit' | 'us_domestic_wire']}
          className={classNames('text-primary', { 'text-secondary': !selected })}
        />
      )}
      <div className="flex flex-grow flex-wrap items-center gap-x-2">
        <div className="flex flex-grow gap-2">
          <div className="text-primary text-base font-medium">{nameMapping[name]}</div>
          {matchingFee && <Chip label={feeString(matchingFee)} size="small" color="grey" />}
          {transferTime === 'Instant' ? (
            <Chip
              icon={<FontAwesomeIcon icon={faBolt} />}
              label={transferTime}
              size="small"
              color="green"
            />
          ) : (
            <Chip label={transferTime} size="small" color="grey" />
          )}
          {temporaryDisabled && (
            <Chip label={'Temporarily unavailable'} size="small" color="warning" />
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingPaymentMethod = () => {
  return (
    <div className="border-level-2 flex h-fit w-full items-center gap-2 gap-2 rounded border px-4 py-3">
      <Skeleton width={16} height={16} variant="rectangular" />
      <Skeleton width={'100%'} />
    </div>
  );
};

export const PaymentMethodSelector = ({
  availableMethods,
  availableDestinations,
  selectedDestination,
  selectedMethod,
  setSelectedDestination,
  setSelectedMethod,
  loadingMethods,
}: {
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination | null;
  selectedMethod: PaymentMethodType | null;
  setSelectedDestination: (value: SetStateAction<NormalizedDestination | null>) => void;
  setSelectedMethod: (value: PaymentMethodType) => void;
  loadingMethods: boolean;
}) => {
  const [checkbookDisabled] = useFeatureFlags('checkbook_killswitch');
  return (
    <div>
      <h4 className="mb-2 font-medium">Transfer speed</h4>
      <RadioGroup className="gap-2">
        {availableMethods.length != 0 ? (
          availableMethods.map((method) => {
            const temporaryDisabled =
              ['debit', 'zelle', 'venmo', 'paypal'].includes(method.type) && checkbookDisabled;

            const isChecked = selectedMethod === method.type;
            return (
              <RadioSelector
                key={method.type}
                value={method.type}
                label={
                  <PaymentMethodItem
                    name={method.type}
                    selected={isChecked}
                    availableMethods={availableMethods}
                    temporaryDisabled={temporaryDisabled}
                  />
                }
                onChange={() => {
                  setSelectedMethod(method.type);
                  if (
                    selectedDestination &&
                    !filterDestinationsBySelectedMethod(
                      availableDestinations,
                      method.type,
                    ).includes(selectedDestination)
                  ) {
                    setSelectedDestination(null);
                  }
                }}
                labelPlacement="start"
                disabled={temporaryDisabled}
                checked={isChecked}
              />
            );
          })
        ) : loadingMethods ? (
          <LoadingPaymentMethod />
        ) : (
          <div>No payment methods available</div>
        )}
      </RadioGroup>
    </div>
  );
};
