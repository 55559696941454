import SetupDebitCardWithVGS from '@app/components/VGSElements/SetupDebitCardWithVGS';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCreditCardBlank } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

interface DebitCardModalProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  forcePrimary?: boolean;
  onSuccess?: () => void;
  payroll?: boolean;
  title?: string;
}

const DebitCardModal = ({
  isActive,
  setIsActive,
  payroll = false,
  onSuccess = () => {},
  title = 'Connect a Debit Card',
}: DebitCardModalProps): ReactElement => {
  const onClose = (): void => {
    setIsActive(false);
  };

  return (
    <Modal fullWidth={false} open={isActive} toggle={() => setIsActive}>
      <ModalHeader title="" onClose={onClose}>
        <div className="bg-accent-23 flex w-fit items-center justify-center rounded-full p-3">
          <FontAwesomeIcon icon={faCreditCardBlank} className="text-primary h-6 w-6 rounded-full" />
        </div>
        <div className="min-h-8 pt-4 text-2xl font-semibold tracking-tight">{title}</div>
      </ModalHeader>
      <ModalBodyContent>
        {
          // TODO (POD3-1069): Try to use with React.lazy to avoid importing unless needed.
          <SetupDebitCardWithVGS payroll={payroll} onSuccess={onSuccess} />
        }
      </ModalBodyContent>
    </Modal>
  );
};

export default DebitCardModal;
