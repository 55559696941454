import { AddressType, Treasury } from '@app/@types/customer.types';
import usePrepaidDetails, { PrepaidDetails } from '@app/components/Prepaid/use-prepaid-details';
import { ErrorNotification } from '@app/components/layout';
import useDisplayUntilConfirmed, {
  createExpirationDate,
} from '@app/hooks/useDisplayUntilConfirmed';
import { getStripeLink } from '@app/pages/Wallet/Onboarding';
import AddFunds from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MANUAL_TOP_UP_NOTIFICATION } from '../PrepaidAccountOverview/TopUpNotification';

export default function TopLevelPrepaidMigrationBanner({
  treasury,
  companyName,
  companyAddress,
  isPrepaid,
}: {
  treasury: Treasury;
  companyName: string;
  companyAddress: AddressType;
  isPrepaid: boolean;
}): ReactElement | null {
  const prepaidDetails = usePrepaidDetails({ treasury, isPrepaid });

  const [checkIfConfirmed, handleConfirm] = useDisplayUntilConfirmed('prepaidMigratePrompt', {
    expiresAt: createExpirationDate(1, 'day'),
  });
  const confirmedAlready = checkIfConfirmed();
  const { pending, churned, step } = prepaidDetails;
  const migrated = !pending || churned;
  const shouldBeAutoOpened =
    prepaidDetails.step &&
    ['pending', 'migrated', 'onboarded'].includes(prepaidDetails.step) &&
    !confirmedAlready &&
    !churned;

  const [isModalOpen, setIsModalOpen] = useState(shouldBeAutoOpened || false);
  const toggle = () => {
    handleConfirm();
    setIsModalOpen(false);
  };

  if (step === 'complete' || step === 'statementDue' || !step) {
    return null;
  }

  const BannerMessage = () =>
    churned ? (
      <>
        You're now on the <b className="font-bold">AtoB Unlimited </b>
        plan. Activate your account for deeper discounts and premium security features.
      </>
    ) : (
      <>
        <span className="font-semibold">
          <b className="font-bold">Important:</b> {migrated ? `We've switched` : `We're switching`}{' '}
          your credit-based fuel card to a pay-as-you-go plan called AtoB Unlimited
          {migrated ? '.' : ' on Monday, December 5th.'}
        </span>
      </>
    );

  return (
    <>
      <div>
        <div
          className={classNames(
            {
              'bg-ds-cool-gray-900': churned || migrated,
              'bg-red-700': !churned && !migrated,
            },
            'relative flex w-full items-center justify-between overflow-hidden bg-no-repeat md:h-[86px]',
          )}
        >
          <div className="gap-x-2 px-[32px] py-[22px] text-base text-gray-100 md:w-full">
            <span className="mr-2">
              <BannerMessage />
            </span>
            <button
              className="inline-block font-semibold underline"
              onClick={() => setIsModalOpen(true)}
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
      <MigrateToPrepaidModal
        isOpen={isModalOpen}
        toggle={toggle}
        prepaidDetails={prepaidDetails}
        companyName={companyName}
        companyAddress={companyAddress}
      />
    </>
  );
}

const MigrateToPrepaidModal = ({
  isOpen,
  toggle,
  prepaidDetails,
  companyName,
  companyAddress,
}: {
  isOpen: boolean;
  toggle: () => void;
  prepaidDetails: PrepaidDetails;
  companyName: string;
  companyAddress: AddressType;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { pending, customerTreasuryDetails, churned } = prepaidDetails;

  const enroll = async () => {
    setLoading(true);
    setError(null);

    try {
      await axios.post('/treasury/onboarding/enroll');
      const stripeLink = await getStripeLink();
      window.location.href = stripeLink;
    } catch (e: unknown) {
      setError((e as AxiosError).message);
    }

    setLoading(false);
  };

  const migrated = !pending || churned;
  const needsToRouteToStripe = customerTreasuryDetails?.status !== 'enrolled';
  const onClick = needsToRouteToStripe
    ? enroll
    : () => {
        navigate('/wallet/overview');
        toggle();
      };
  let buttonText = 'Enroll in AtoB Wallet';
  buttonText = loading ? 'Loading...' : buttonText;

  const headerCopy = migrated
    ? "You're now on the AtoB Unlimited plan"
    : `You'll be switched to the new AtoB Unlimited plan.`;

  const ModalMessage = () => (
    <>
      <p className="text-base leading-6">
        At AtoB we're constantly evaluating how customers use our products and strive to provide the
        best product possible. This is why{' '}
        {migrated ? `you've been switched` : `we'll be switching you`} to the pay-as-you-go AtoB
        Unlimited plan.
      </p>
      <div className="m-1" />
      <p className="text-atob-green text-base font-bold leading-6">
        <Link to="/unlimited">Learn more about Unlimited.</Link>
      </p>
      <div className="m-1" />
      <h2 className="text-2xl font-semibold">Getting started is simple</h2>
      <p className="text-base leading-6">
        Set up your Wallet, add funds, and use your existing AtoB card toward purchases.
      </p>
    </>
  );

  return (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="" onClose={toggle} />
      <ModalBodyContent>
        {error && <ErrorNotification error={error} />}
        <div className="flex flex-col items-center">
          <FontAwesomeIcon
            icon={faCreditCardFront}
            className="fa-light bg-green8 text-atob-green mr-1 rounded-full p-5"
            size="2x"
          />
          <div className="m-2" />
          <h2 className="text-center text-2xl font-bold">{headerCopy}</h2>
          <div className="m-4" />
          <div className="flex max-w-[400px] flex-col gap-2 text-center font-normal text-gray-900">
            <ModalMessage />
          </div>
          <div className="mb-8 mt-8 flex w-full justify-center">
            <div className="min-w-[120px]">
              {needsToRouteToStripe ? (
                <LoadingButton onClick={onClick} color="primary" loading={loading}>
                  {buttonText}
                </LoadingButton>
              ) : (
                <>
                  <div className="mb-4 flex flex-col items-start justify-center px-6 md:px-8 lg:items-center">
                    <AddFunds
                      buttonProps={{
                        disabled: prepaidDetails.customerTreasuryDetails?.status !== 'enrolled',
                        text: 'Deposit to your Wallet',
                        primary: true,
                      }}
                      customerCompanyName={companyName}
                      customerCompanyAddress={companyAddress}
                      onAddFunds={() => {
                        window.location.href = `/?${MANUAL_TOP_UP_NOTIFICATION}=true`;
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
