import { guardAxiosError } from '@app/utils/error/guards';
import { isOTPError } from '@app/utils/error/isRetryableError';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Button, Divider } from '@mui/material';
import { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { RecipientData } from '../transfer.types';
import {
  AmountInput,
  DescriptionInput,
  DestinationSelector,
  PaymentMethodSelector,
} from './Inputs';
import { ConfirmationModal, SuccessModal } from './Modals';
import { useWalletTransfer, WalletTransferStep } from './useWalletTransfer';
import {
  FeeConfiguration,
  filterDestinationsBySelectedMethod,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
  WithdrawalState,
  withdrawFunds,
} from './utils';

export const TransferPanel = ({
  loadingMethods,
  loadingDestinations,
  availableMethods,
  availableDestinations,
  reset,
  onCompletedTransfer,
  walletBalance,
  transferKind,
  recipients,
}: {
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  loadingMethods: boolean;
  loadingDestinations: boolean;
  reset: () => void;
  onCompletedTransfer: () => void;
  walletBalance?: string | null;
  transferKind: TransferKind;
  recipients: RecipientData[];
}) => {
  const [withdrawalState, setWithdrawalState] = useState<WithdrawalState>('initial');
  const { goPrevStep } = useWalletTransfer();
  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<NormalizedDestination | null>(
    null,
  );
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [validAmount, setValidAmount] = useState(true);
  const { addToast } = useToasts();
  const filteredDestinations = useMemo(() => {
    return filterDestinationsBySelectedMethod(availableDestinations, selectedMethod);
  }, [availableDestinations, selectedMethod]);
  const submitFunds = async ({
    destination,
    selectedTransferMethod,
  }: {
    destination: NormalizedDestination;
    selectedTransferMethod: FeeConfiguration;
  }) => {
    if (withdrawalState === 'initial') {
      setWithdrawalState('confirmation');
      return;
    }

    setLoading(true);

    try {
      await withdrawFunds(
        amount,
        description,
        uuid(),
        selectedTransferMethod,
        destination.id,
        destination.recipientType === 'own' ? 'own_transfer' : 'external_transfer',
      );
      onCompletedTransfer();
      setWithdrawalState('success');
    } catch (e: unknown) {
      if (isOTPError(e)) {
        // Skip showing this error, because it will be intercepted by the OTP handler
        return;
      }

      const defaultError = 'Something went wrong. Please try again later.';
      if (guardAxiosError(e)) {
        const message = `There was an error: ${e?.response?.data?.errors?.[0] || defaultError}`;
        addToast(message, { appearance: 'error' });
      } else {
        addToast(defaultError, { appearance: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const selectedMethodConfiguration = availableMethods.find(
    (method) => method.type === selectedMethod,
  );

  return (
    <>
      <div className="mb-[72px] flex-1">
        <div className="mb-12 flex flex-col gap-6 px-6 md:px-8">
          <PaymentMethodSelector
            availableMethods={availableMethods}
            availableDestinations={availableDestinations}
            selectedDestination={selectedDestination}
            selectedMethod={selectedMethod}
            setSelectedDestination={setSelectedDestination}
            setSelectedMethod={setSelectedMethod}
            loadingMethods={loadingMethods}
          />
          <DestinationSelector
            availableDestinations={filteredDestinations}
            selectedDestination={selectedDestination}
            selectedMethod={selectedMethod}
            setSelectedDestination={setSelectedDestination}
            loadingDestinations={loadingDestinations}
            transferKind={transferKind}
            recipients={recipients}
          />
          <AmountInput
            amount={amount}
            setAmount={setAmount}
            walletBalance={walletBalance}
            setValidAmount={setValidAmount}
            feeConfiguration={selectedMethodConfiguration}
          />
          <DescriptionInput description={description} setDescription={setDescription} />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-full overflow-hidden bg-white">
        <Divider />
        <div className="flex gap-3 px-6 pb-8 pt-4 md:px-8">
          <Button
            className="w-full"
            onClick={() => {
              if (transferKind == 'own_transfer') {
                goPrevStep(WalletTransferStep.TransferType);
              } else {
                goPrevStep();
              }
            }}
            color="secondary"
          >
            Previous step
          </Button>

          <LoadingButton
            color="primary"
            fullWidth
            onClick={() =>
              submitFunds({
                destination: selectedDestination!,
                selectedTransferMethod: selectedMethodConfiguration!,
              })
            }
            disabled={!selectedMethod || !selectedDestination || !amount || !validAmount}
            loading={loading}
          >
            Transfer funds
          </LoadingButton>
        </div>
      </div>

      {withdrawalState === 'confirmation' && selectedMethod != null && (
        <ConfirmationModal
          open
          onClose={() => setWithdrawalState('initial')}
          destination={selectedDestination!}
          method={selectedMethod}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
          onConfirm={() =>
            submitFunds({
              destination: selectedDestination!,
              selectedTransferMethod: selectedMethodConfiguration!,
            })
          }
          loading={loading}
        />
      )}
      {withdrawalState === 'success' && selectedMethod != null && (
        <SuccessModal
          open
          onClose={reset}
          destination={selectedDestination!}
          method={selectedMethod}
          amount={amount}
          fee={selectedMethodConfiguration!}
          description={description}
        />
      )}
    </>
  );
};
