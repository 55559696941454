import { Button } from '@mui/material';
import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

const CongratulationsView = (): ReactElement => {
  const navigate = useNavigate();
  const onClickGoToDashboard = () => {
    navigate('/');
  };

  return (
    <div className="font-mTFF space-y-8 px-6">
      <h1 className="text-[40px] font-bold leading-[52px]">Congrats!</h1>
      <p>You have successfully been transferred to an Uber Freight Carrier Card account!</p>
      <p>
        Continue using your existing cards to receive deeper fuel discounts, and free same-day
        payouts.
      </p>
      <Button
        fullWidth
        className="bg-black text-lg font-medium leading-6 hover:bg-[rgb(51,51,51)]"
        onClick={onClickGoToDashboard}
      >
        View account dashboard
      </Button>
    </div>
  );
};

export default CongratulationsView;
