import { useEventDismissed } from '@app/hooks/useEventDismissed';
import useProduct from '@app/hooks/useProduct';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, useState } from 'react';

export function WalletWelcomePopup(): ReactElement | null {
  const [walletLite, treasuryTrusted] = useProduct('wallet_lite', 'treasury_trusted');
  const { dismissed, loading: isLoading, setDismissed } = useEventDismissed('wallet_welcome_popup');
  const [isOpen, setIsOpen] = useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if ((!walletLite && !treasuryTrusted) || isLoading || dismissed) {
    return null;
  }

  const onClose = () => {
    setDismissed();
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} maxWidth="xs">
      <ModalHeader
        title={`Welcome to Wallet`}
        onClose={onClose}
        headerImage={
          <img
            className="w-full max-w-lg"
            src={
              !fullScreen ? '/images/welcome-to-wallet.png' : '/images/welcome-to-wallet-mobile.png'
            }
          />
        }
      />
      <ModalBodyContent>
        To withdraw funds, click <span className="font-bold">Send & Withdraw</span>, select which
        method you would like to use and add your information.
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" size="medium" onClick={onClose}>
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
}
