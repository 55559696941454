import { EndpointResponse } from '@app/@types/api.types';
import { CardDataWithRelationships } from '@app/@types/card.types';
import Header from '@app/components/Navigation/Header';
import { ErrorNotification, Loading } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { apiGetFetcher } from '@app/utils/data/fetchers';

import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import CardDetailsPage from './CardDetailsPage';

export function CardDetails({
  allowSetSpendLimit,
  smsUnlockEnabled,
  telematicsEnabled,
  resourceTagsEnabled,
}: {
  allowSetSpendLimit: boolean;
  smsUnlockEnabled: boolean;
  telematicsEnabled: boolean;
  resourceTagsEnabled: boolean;
}): ReactElement | null {
  const { id: cardId } = useParams();
  const navigate = useNavigate();

  const {
    data: card,
    isLoading: isCardLoading,
    error,
    mutate,
    error: cardLoadError,
  } = useSWR<EndpointResponse<CardDataWithRelationships>>(
    {
      url: `/cards/${cardId}`,
      params: {
        include: 'resource_tag',
      },
    },
    apiGetFetcher,
  );

  if (cardLoadError) {
    return <ErrorNotification error={error.message} />;
  }

  if (isCardLoading) {
    return (
      <PageContentWrapper header={<Header title="Card Details" onBack={() => navigate(-1)} />}>
        <Loading />
      </PageContentWrapper>
    );
  }

  if (card) {
    return (
      <CardDetailsPage
        card={card.data}
        allowSetSpendLimit={allowSetSpendLimit}
        smsUnlockEnabled={smsUnlockEnabled}
        onCardChange={mutate}
        telematicsEnabled={telematicsEnabled}
        resourceTagsEnabled={resourceTagsEnabled}
      />
    );
  }
  return null;
}

export default CardDetails;
