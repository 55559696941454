import { Button } from '@mui/material';
import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

const InterestedView = (): ReactElement => {
  const navigate = useNavigate();
  const onClickGoToDashboard = () => {
    navigate('/');
  };

  return (
    <div className="font-mTFF space-y-8 px-6">
      <h1 className="text-[40px] font-bold leading-[52px]">Thanks for your interest!</h1>
      <p>
        An AtoB Team member will email you within one business day about next steps for transferring
        your AtoB account to an Uber Freight Carrier Card.
      </p>
      <Button
        fullWidth
        className="bg-black text-lg font-medium leading-6 hover:bg-[rgb(51,51,51)]"
        onClick={onClickGoToDashboard}
      >
        Close
      </Button>
    </div>
  );
};

export default InterestedView;
