import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import StripeElementsWrapper from '@app/components/StripeElements/StripeElementsWrapper';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { isProduction } from '@app/utils/environment';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { renderFields } from '@highnoteplatform/card-viewer';
import * as Sentry from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import axios from 'axios';
import { ReactElement, useState } from 'react';

export const CardSensitiveData = ({
  externalCardId,
  cardId,
}: {
  externalCardId: string;
  cardId: number;
}): ReactElement | null => {
  if (externalCardId.startsWith('cd_')) {
    return <CardSensitiveDataHighnote highnoteCardId={externalCardId} cardId={cardId} />;
  }
  return <CardSensitiveDataStripe stripeCardId={externalCardId} cardId={cardId} />;
};

const CardSensitiveDataStripe = StripeElementsWrapper(
  ({ stripeCardId, cardId }: { stripeCardId: string; cardId: number }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [fetchingCardNumber, setFetchingCardNumber] = useState<boolean>(false);

    const stripe: Stripe | null = useStripe();
    const stripeElements = useElements();

    const getStripeCardDetails = async () => {
      if (!stripe || !stripeElements) return;

      setFetchingCardNumber(true);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const nonceResult = await stripe.createEphemeralKeyNonce({
        issuingCard: stripeCardId,
      });

      try {
        const nonce = nonceResult.nonce;
        const ephemeralKeyResult: { data: { ephemeralKeySecret: string } } = await axios.get(
          `/cards/${cardId}/ephemeral_key`,
          { params: { nonce: nonce } },
        );
        const ephemeralKeySecret = ephemeralKeyResult.data.ephemeralKeySecret;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cardNumber = stripeElements.create('issuingCardNumberDisplay', {
          issuingCard: stripeCardId,
          nonce: nonce,
          ephemeralKeySecret: ephemeralKeySecret,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cardExpiry = stripeElements.create('issuingCardExpiryDisplay', {
          issuingCard: stripeCardId,
          nonce: nonce,
          ephemeralKeySecret: ephemeralKeySecret,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cardCvc = stripeElements.create('issuingCardCvcDisplay', {
          issuingCard: stripeCardId,
          nonce: nonce,
          ephemeralKeySecret: ephemeralKeySecret,
        });
        cardNumber.mount('#card-number');
        cardExpiry.mount('#card-expiry');
        cardCvc.mount('#card-cvc');
      } catch (e) {
        // Close the modal if the user sees an MFA error. The user will need to re-open it
        // after going through MFA.
        if (
          guardAxiosError(e) &&
          e.response?.status === 403 &&
          e.response?.data?.reason === 'mfa_required'
        ) {
          setShowModal(false);
          return;
        }
        throw e;
      } finally {
        setFetchingCardNumber(false);
      }
    };

    return (
      <CardSensitiveDataView
        showModal={showModal}
        setShowModal={setShowModal}
        getCardDetails={getStripeCardDetails}
        fetchingCardNumber={fetchingCardNumber}
      />
    );
  },
  ApiEndpoints.FUEL_CARD_PAYMENTS_ENDPOINTS.ISSUING_INTEGRATION_ENDPOINT,
  false,
);

const CardSensitiveDataHighnote = ({
  highnoteCardId,
  cardId,
}: {
  highnoteCardId: string;
  cardId: number;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fetchingCardNumber, setFetchingCardNumber] = useState<boolean>(false);

  const getHighnoteCardDetails = async () => {
    setFetchingCardNumber(true);

    try {
      const clientTokenResponse = await axios.get(
        // eslint-disable-next-line @cspell/spellchecker
        `/cards/${cardId}/generate_highnote_client_token`,
      );
      const clientToken = clientTokenResponse.data.token;

      const result = await renderFields({
        clientToken,
        paymentCardId: highnoteCardId,
        onError: (error) => {
          Sentry.captureException(error);
        },
        elements: {
          cardNumber: { selector: '#card-number' },
          cvv: { selector: '#card-cvc' },
          expirationDate: { selector: '#card-expiry' },
        },
        environment: isProduction() ? 'live' : 'test',
      });

      setTimeout(() => {
        result.toggleCardNumberMask();
      }, 1000);

      return () => {
        return result.unmount();
      };
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setFetchingCardNumber(false);
    }
  };

  return (
    <CardSensitiveDataView
      showModal={showModal}
      setShowModal={setShowModal}
      getCardDetails={getHighnoteCardDetails}
      fetchingCardNumber={fetchingCardNumber}
    />
  );
};

const CardSensitiveDataView = ({
  showModal,
  setShowModal,
  getCardDetails,
  fetchingCardNumber,
}: {
  setShowModal: (b: boolean) => void;
  getCardDetails: () => void;
  showModal: boolean;
  fetchingCardNumber: boolean;
}) => {
  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <a
        className="inline-flex cursor-pointer items-center text-sm font-medium leading-4 underline hover:opacity-75 focus:outline-none"
        onClick={() => {
          setShowModal(true);
          getCardDetails();
        }}
      >
        Show card details
      </a>
      <Modal open={showModal} toggle={toggleModal}>
        <ModalHeader title="Card Details" onClose={toggleModal} />
        <ModalBodyContent overflowVisible={true}>
          {fetchingCardNumber && <SpinnerBoundary />}
          <div className="mb-6 w-full pr-0 md:pr-8">
            <div className="flex h-full flex-col justify-between">
              <div className="flex justify-between">
                <div className="flex w-6/12 flex-col">
                  <div className="fs-mask data-hj-suppress text-grey6 text-sm	font-normal">
                    Card Number: <div id="card-number" className="h-7" />
                  </div>
                  <div className="fs-mask data-hj-suppress text-grey6 text-sm	font-normal">
                    Expiration <div id="card-expiry" className="h-7" />
                  </div>
                  <div className="fs-mask data-hj-suppress text-grey6 text-sm	font-normal">
                    CVV: <div id="card-cvc" className="h-7" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <a
                className="mb-12 w-full text-center text-sm underline underline-offset-4"
                href="https://atobco.zendesk.com/hc/en-us/articles/16160404307611"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to add Virtual Cards to your phone?
              </a>
            </div>
          </div>
        </ModalBodyContent>
      </Modal>
    </>
  );
};
