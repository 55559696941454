import { ReactElement } from 'react';

const CardDetailsTabPanel = (): ReactElement => {
  return (
    <>
      <div className="mt-6 w-full flex-col">
        <div className="w-full">
          <label htmlFor="vgs-card-number" className="row-span-full text-sm font-medium">
            Card number
          </label>
          <div className="inline-flex h-10" id="vgs-card-number"></div>
        </div>
        <div className="flex w-full">
          <div className="w-1/2 flex-col">
            <label htmlFor="vgs-card-expiry" className="text-sm font-medium">
              Expiration
            </label>
            <div className="h-10" id="vgs-card-expiry"></div>
          </div>
          <div className="w-1/2 flex-col">
            <label htmlFor="vgs-card-cvc" className="text-sm font-medium">
              CCV
            </label>
            <div className="h-10" id="vgs-card-cvc"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetailsTabPanel;
