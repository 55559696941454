import { CustomerData } from '@app/@types/customer.types';
import { CreditLimitIncreaseRequestModal } from '@app/components/Modals/CreditLimitIncreaseRequest/CreditLimitIncreaseRequestModal';
import { ULToCreditRequest } from '@app/components/Modals/ULToCreditRequest/ULToCreditRequest';

import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const RequestCredit = (props: { customer: CustomerData; isPrepaid: boolean }): ReactElement => {
  const navigate = useNavigate();

  const isPrepaid = props.isPrepaid;

  return (
    <>
      {isPrepaid && <ULToCreditRequest open={true} toggleModal={() => navigate('/')} />}
      {!isPrepaid && <CreditLimitIncreaseRequestModal open={true} onClose={() => navigate('/')} />}
    </>
  );
};

export default RequestCredit;
