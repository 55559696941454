import BillingBannerElement from '@app/components/Banner/BillingBannerElement';
import IssuingOnboardingBanner from '@app/components/Banner/IssuingOnboardingBanner';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { useIssuingDetails } from '@app/hooks/useIssuingDetails';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import { ErrorBanner } from './Designs/ErrorBanner';
import IssuingTosAcceptanceBanner from './IssuingToSAcceptanceBanner';

const GlobalBanners = (): ReactElement | null => {
  const customer = useCustomer();
  const [hasFlexOffer, fifthThirdIssue] = useFeatureFlags(
    'ul_to_flex_promo_v2',
    'incident_fifth_third',
  );
  const isUF = useIsUberfreight();
  const { partnerName } = useChannelPartner();
  const [prepaid, billing, treasury] = useProduct('prepaid', 'billing', 'treasury');

  const migratingToFlex = hasFlexOffer && prepaid;
  const { issuingOnboardingIncomplete } = useIssuingDetails();

  if (customer.hide_banners) {
    return null;
  }

  const banners = [];

  if (fifthThirdIssue) {
    banners.push(
      <ErrorBanner key="fifth-third-banner">
        <div className="font-semibold">
          We are aware of a potential issue around the use of {partnerName ?? 'AtoB'} Unlimited
          cards. If you are having trouble, please call customer support at{' '}
          {isUF ? '831-704-9242' : '833-947-2862'} for assistance.
        </div>
      </ErrorBanner>,
    );
  }

  banners.push(<IssuingTosAcceptanceBanner key="issuing_tos_acceptance" />);

  const showIssuingOnboardingBanner =
    customer.issuing?.force_show_banner ||
    (issuingOnboardingIncomplete &&
      !customer.issuing?.force_hide_banner &&
      !customer.suspended_at &&
      !customer.is_overdue &&
      !migratingToFlex);
  if (showIssuingOnboardingBanner) {
    banners.push(<IssuingOnboardingBanner customer={customer} key="onboarding" />);
  }

  if (billing) {
    banners.push(
      <BillingBannerElement
        key="billing"
        customer={customer}
        isPrepaid={prepaid}
        hasTreasury={treasury}
      />,
    );
  }

  return <>{banners}</>;
};

export default GlobalBanners;
