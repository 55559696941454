import { EndpointResponse } from '@app/@types/api.types';
import { Treasury } from '@app/@types/customer.types';
import { FuelCardAccountData } from '@app/@types/fuel-card-account.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useLocalStorageValues from '@app/hooks/useLocalStorageValues';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import currency from 'currency.js';
import useSWR from 'swr';

export const PREPAID_OPT_IN_CONFIRMED = 'prepaid_opt_in_confirmed';

type PrepaidCustomerOnboardingStep =
  | 'optedIn'
  | 'pending'
  | 'migrated'
  | 'onboarded'
  | 'toppedUp'
  | 'accountSetupFeePaid'
  | 'cardsOrdered'
  | 'statementDue'
  | 'complete'
  | 'suspended';

type OnboardingState =
  | 'optIn'
  | 'pending'
  | 'migrating'
  | 'netNew'
  | 'churned'
  | 'zeroBalance'
  | null;

export type PrepaidDetails = {
  step: PrepaidCustomerOnboardingStep | null;
  pending: boolean;
  churned: boolean;
  optIn: boolean;
  netNew: boolean;
  customerTreasuryDetails: Treasury | null;
  onboardingErrors: { code: string; reason: string }[];
  onboardingState: OnboardingState;
};

const getOptInStep = ({
  isPrepaid,
  optedIn,
  enrolledInTreasury,
  hasEverToppedUpSuccessfully,
  hasStatementDue,
}: {
  isPrepaid: boolean;
  optedIn: boolean;
  enrolledInTreasury: boolean;
  hasEverToppedUpSuccessfully: boolean;
  hasStatementDue: boolean;
}): PrepaidCustomerOnboardingStep => {
  if (!optedIn) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  if (!enrolledInTreasury) {
    return 'optedIn';
  }

  if (!hasEverToppedUpSuccessfully) {
    return 'onboarded';
  }

  if (!isPrepaid) {
    return 'toppedUp';
  }

  if (hasStatementDue) {
    return 'statementDue';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
};

const getPendingStep = ({
  enrolledInTreasury,
  hasEverToppedUpSuccessfully,
}: {
  enrolledInTreasury: boolean;
  hasEverToppedUpSuccessfully: boolean;
}): PrepaidCustomerOnboardingStep => {
  if (!enrolledInTreasury) {
    return 'pending';
  }
  if (!hasEverToppedUpSuccessfully) {
    return 'onboarded';
  }
  return 'toppedUp';
};

const getForcedMigrationStep = ({
  enrolledInTreasury,
  hasEverToppedUpSuccessfully,
  hasStatementDue,
}: {
  enrolledInTreasury: boolean;
  hasEverToppedUpSuccessfully: boolean;
  hasStatementDue: boolean;
}): PrepaidCustomerOnboardingStep => {
  if (!enrolledInTreasury) {
    return 'migrated';
  }

  if (!hasEverToppedUpSuccessfully) {
    return 'onboarded';
  }

  if (hasStatementDue) {
    return 'statementDue';
  }

  return 'complete';
};

const getNetNewStep = ({
  enrolledInTreasury,
  hasEverToppedUpSuccessfully,
  isCardholderActive,
  accountSetupFeeRequired,
  hasWalletBalance,
  wasRecentlyActivated,
  canOrderVirtualCards,
  hasVirtualCards,
}: {
  enrolledInTreasury: boolean;
  hasEverToppedUpSuccessfully: boolean;
  isCardholderActive: boolean;
  accountSetupFeeRequired: boolean;
  hasWalletBalance: boolean;
  wasRecentlyActivated: boolean;
  canOrderVirtualCards: boolean;
  hasVirtualCards: boolean;
}): PrepaidCustomerOnboardingStep => {
  if (!enrolledInTreasury) {
    return 'optedIn';
  }

  /**
   * For customers not in feature flag groups "unlimited_preshipment_activation_fee" or "virtual_cards_pilot",
   * their next step is to topup.
   */
  if (
    !hasWalletBalance &&
    !hasEverToppedUpSuccessfully &&
    !accountSetupFeeRequired &&
    !canOrderVirtualCards
  ) {
    return 'onboarded';
  }

  /**
   * For customers who can order virtual cards, their next step is to order cards.
   * After their cards are ordered (whether physical or virtual), their cardholder will become active.
   */
  if (canOrderVirtualCards) {
    if (!isCardholderActive) {
      return 'onboarded';
    }
    // Customer ordered virtual cards
    if (hasVirtualCards && !hasWalletBalance && wasRecentlyActivated) {
      return 'cardsOrdered';
    }
    // Customer ordered physical cards
    if (accountSetupFeeRequired && !hasWalletBalance && wasRecentlyActivated) {
      return 'cardsOrdered';
    }
  } else if (accountSetupFeeRequired) {
    /**
     * For customers in feature flag group "unlimited_preshipment_activation_fee",
     * their next step is to topup for their account setup fee which ships cards and turns their cardholder active.
     */
    if (!isCardholderActive) {
      return 'onboarded';
    } else if (!hasWalletBalance && wasRecentlyActivated) {
      return 'accountSetupFeePaid';
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
};

const getZeroBalanceStep = ({
  enrolledInTreasury,
  hasEverToppedUpSuccessfully,
}: {
  enrolledInTreasury: boolean;
  hasEverToppedUpSuccessfully: boolean;
}): PrepaidCustomerOnboardingStep => {
  if (!enrolledInTreasury) {
    return 'migrated';
  }

  if (!hasEverToppedUpSuccessfully) {
    return 'onboarded';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
};

const formatStripeCode = (code: string): string => {
  if (code.match('person') !== null) {
    return '';
  }

  return code
    .split('_')
    .map((word) => `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`)
    .join(' ');
};

export default function usePrepaidDetails({
  treasury,
  isPrepaid,
  cardholderStatus,
  activatedAt,
  hasVirtualCards,
}: {
  treasury: Treasury;
  isPrepaid: boolean;
  cardholderStatus?: string;
  activatedAt?: string;
  hasVirtualCards?: boolean;
}): PrepaidDetails {
  const [pending, churned, migrating, optIn, netNew, zeroBalance] = useFeatureFlags(
    'prepaid_pending',
    'prepaid_churned',
    'prepaid_migration',
    'prepaid_opt_in',
    'prepaid_net_new',
    'prepaid_zero_balance',
  );

  const { data: fuelCardAccountData, isLoading } = useSWR<EndpointResponse<FuelCardAccountData>>(
    { url: '/fuel_card_account' },
    apiGetFetcher,
  );
  const enrolledInTreasury = treasury.status === 'enrolled';
  const isSuspended = treasury.status === 'suspended';
  const onboardingErrors =
    treasury.onboarding_errors?.map(({ code, reason }) => ({
      code: formatStripeCode(code),
      reason,
    })) || [];

  const doNotShowSteps =
    !isSuspended && !pending && !churned && !migrating && !optIn && !netNew && !zeroBalance;
  const hasEverToppedUpSuccessfully = treasury.has_inbound_transfer_completed;
  const hasWalletBalance =
    treasury.financial_account?.balance && treasury.financial_account.balance !== '$0.00';

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const wasRecentlyActivated = activatedAt && Date.parse(activatedAt) >= oneWeekAgo.getTime();

  const [UNLIMITED_PRESHIPMENT_ACTIVATION_FEE] = useFeatureFlags(
    'unlimited_preshipment_activation_fee',
  );
  const isCardholderActive = cardholderStatus === 'active';

  const [getLocalStorageValues] = useLocalStorageValues();
  const localStorageValues = getLocalStorageValues();
  const optedIn = !!localStorageValues[PREPAID_OPT_IN_CONFIRMED];

  const [canOrderVirtualCards] = useFeatureFlags('virtual_cards_pilot');

  const getOnboardingStep = (): PrepaidCustomerOnboardingStep | null => {
    if (doNotShowSteps || isLoading || !fuelCardAccountData) {
      return null;
    }

    const hasStatementDue = currency(fuelCardAccountData?.data?.account_balance ?? 0).value > 0;

    if (isSuspended) {
      return 'suspended';
    }

    if (optIn) {
      return getOptInStep({
        isPrepaid,
        optedIn,
        enrolledInTreasury,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hasEverToppedUpSuccessfully,
        hasStatementDue,
      });
    }

    if (pending) {
      return getPendingStep({
        enrolledInTreasury,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hasEverToppedUpSuccessfully,
      });
    }

    if (migrating || churned) {
      return getForcedMigrationStep({
        enrolledInTreasury,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hasEverToppedUpSuccessfully,
        hasStatementDue,
      });
    }

    if (netNew) {
      return getNetNewStep({
        enrolledInTreasury,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hasEverToppedUpSuccessfully,
        isCardholderActive,
        accountSetupFeeRequired: UNLIMITED_PRESHIPMENT_ACTIVATION_FEE,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hasWalletBalance,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        wasRecentlyActivated,
        canOrderVirtualCards,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        hasVirtualCards,
      });
    }

    if (zeroBalance) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return getZeroBalanceStep({ enrolledInTreasury, hasEverToppedUpSuccessfully });
    }

    return null;
  };

  let onboardingState = null;
  if (isSuspended) {
    onboardingState = 'suspended';
  } else if (pending) {
    onboardingState = 'pending';
  } else if (churned) {
    onboardingState = 'churned';
  } else if (migrating) {
    onboardingState = 'migrating';
  } else if (optIn) {
    onboardingState = 'optIn';
  } else if (netNew) {
    onboardingState = 'netNew';
  } else if (zeroBalance) {
    onboardingState = 'zeroBalance';
  }

  return {
    pending,
    churned,
    optIn,
    netNew,
    step: getOnboardingStep(),
    customerTreasuryDetails: treasury,
    onboardingErrors,
    onboardingState: onboardingState as OnboardingState,
  };
}
