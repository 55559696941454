import DialogDropzone from '@app/components/DialogDropzone/DialogDropzone';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useWindowWidth from '@app/hooks/useWindowWidth';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';
import Plaid from '../../../components/Plaid';

export interface UnlimitedToFlexMigrationConnectBankAccountProps {
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
  showBankStatementOption: boolean;
}

const UnlimitedToFlexMigrationConnectBankAccount = ({
  onNext,
  onBack,
  onClose,
  showBankStatementOption = true,
}: UnlimitedToFlexMigrationConnectBankAccountProps): ReactElement | null => {
  const { addToast } = useToasts();
  const { isSmallScreen } = useWindowWidth();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadDocuments = async (files: File[]) => {
    if (!files || files.length === 0) {
      addToast('Please select files to upload.', { appearance: 'error' });
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('bank_statements[]', files[i]);
    }
    try {
      const response = await axios.patch('/customer/upload_statements', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        addToast('Documents uploaded successfully!');
      }
    } catch (error) {
      addToast('Failed to upload documents.', { appearance: 'error' });
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full">
          <SpinnerBoundary />
        </div>
      )}
      <ModalHeader title="Connect Primary Bank Account" onClose={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="my-4">
          We use Plaid to connect your account. Please connect using your account credentials — we
          don't support microdeposit verification at this time.
          <br />
          <div className="mt-2">
            <a
              href="https://www.plaid.com"
              className="mt-2 underline decoration-1 underline-offset-2"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} size="2x" className="scale-75 text-black" />
            <div className="ml-2">
              <div className="text-sm">Easy, private, and secure</div>
            </div>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} size="2x" className="scale-75 text-black" />
            <div className="ml-2">
              <div className="text-sm">We will never sell your personal info</div>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex flex-1 flex-col">
          <div className="flex w-full gap-1 pb-3">
            <Button className="flex-1" color="secondary" onClick={onBack}>
              Back
            </Button>
            <Plaid
              setError={() =>
                addToast(
                  'There was an error connecting to your bank account. Please contact support for assistance.',
                  {
                    appearance: 'error',
                  },
                )
              }
              buttonProps={{
                className: 'flex-1',
                primary: true,
              }}
              text={isSmallScreen ? 'Connect' : 'Connect Bank Account'}
              onSuccessCallback={onNext}
              onLinkingCallback={(phase: string) => {
                if (phase === 'START') {
                  setLoading(true);
                } else if (phase === 'END') {
                  setLoading(false);
                }
              }}
              flow="ul-to-flex-migration-modal"
            />
          </div>

          {showBankStatementOption && (
            <div>
              <span className="font-medium">Can&apos;t find your bank? </span>
              <span className="cursor-pointer underline" onClick={handleOpen}>
                Upload 3 months of business bank statements
              </span>
              <DialogDropzone
                open={open}
                handleSubmit={(files) => {
                  uploadDocuments(files);
                  handleClose();
                  onNext();
                }}
                handleClose={handleClose}
                options={{
                  accept: { 'application/pdf': ['.pdf'] },
                  maxSize: 10000000,
                  maxFiles: 10,
                }}
              />
            </div>
          )}
        </div>
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationConnectBankAccount;
