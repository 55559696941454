import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { Treasury } from '@app/@types/customer.types';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import Skeleton from '@app/components/layout/Skeleton';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@app/hooks/paging/types';
import useCustomer from '@app/hooks/useCustomer';
import useProduct from '@app/hooks/useProduct';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ReactElement } from 'react';
import useSWR from 'swr';
import Onboarding from './Onboarding';
import WalletOverview from './WalletOverview';
import WalletPageHeader from './WalletPageHeader';
import WalletTransactions from './WalletTransactions';

function shouldOnboard(customerTreasuryDetails: Treasury): boolean {
  return (
    customerTreasuryDetails?.status === 'not_enrolled' ||
    customerTreasuryDetails?.status === 'started'
  );
}

export type TransactionStatus = 'success' | 'failed' | 'pending' | 'canceled' | 'returned';

export type WalletTransaction = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  receipt_url: string;
  description: string;
  direction: 'inbound' | 'outbound';
  source: string;
  destination: string;
  key: string;
  id: string;
  fee?: string;
};

export type WalletCredit = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  key: string;
  id: string;
};

export default function WalletHome(): ReactElement {
  const customer = useCustomer();
  const [walletLite, treasuryTrusted] = useProduct('wallet_lite', 'treasury_trusted');
  const isTrusted = walletLite || treasuryTrusted;

  const [page, setPage] = useSingleUrlState<number>('page', 1);

  const {
    data,
    isLoading,
    error: transactionsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<WalletTransaction>>(
    {
      url: '/treasury/transactions',
      params: {
        per: DEFAULT_TRANSACTIONS_PAGE_SIZE,
        page,
        all: false,
      },
    },
    apiGetFetcher,
  );

  const fetchTransactions = () => {
    setPage(1);
    void mutate();
  };

  if (shouldOnboard(customer.treasury)) {
    return (
      <>
        <MobileNavHeader title="Wallet" />
        <PageContentWrapper>
          <Onboarding customerTreasuryDetails={customer.treasury} />
        </PageContentWrapper>
      </>
    );
  }

  return (
    <PageContentWrapper
      header={
        <WalletPageHeader
          balance={customer.treasury.financial_account?.balance}
          status={customer.treasury.status}
          isTrusted={isTrusted}
        />
      }
    >
      <WalletOverview
        financialAccount={customer.treasury.financial_account}
        loading={false}
        isTrusted={isTrusted}
        onAddFunds={fetchTransactions}
        onWithdrawFunds={fetchTransactions}
        customerCompanyName={customer.company_name}
        customerCompanyAddress={customer.company_address}
      />
      {!isLoading ? (
        <WalletTransactions
          transactionsPage={data?.data ?? []}
          transactionsError={transactionsError}
          metadata={data?.meta}
          goToPage={setPage}
        />
      ) : (
        <Skeleton />
      )}
    </PageContentWrapper>
  );
}
