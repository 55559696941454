import { PaginationMeta } from '@app/@types/api.types';
import { ErrorNotification } from '@app/components/layout';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement, useState } from 'react';
import WalletTransactionsTable from './WalletTransactions/WalletTransactionsTable';
import type { WalletTransaction } from './WalletHome';
import type { GoToPageAction } from '@app/hooks/paging/types';

const TRANSACTIONS_TAB: TransactionsTab[] = [
  {
    name: 'All transactions',
    tab: 'all',
  },
  {
    name: 'Completed',
    tab: 'success',
  },
  {
    name: 'Pending',
    tab: 'pending',
  },
  {
    name: 'Failed',
    tab: 'failed',
  },
];

type TransactionsTab = {
  name: 'All transactions' | 'Completed' | 'Pending' | 'Failed';
  tab: 'all' | 'success' | 'pending' | 'failed';
};

export default function WalletTransactions({
  transactionsPage,
  transactionsError,
  metadata,
  goToPage,
}: {
  transactionsPage: WalletTransaction[];
  transactionsError: unknown;
  metadata: PaginationMeta | undefined;
  goToPage: GoToPageAction;
}): ReactElement {
  const [currentTransactionsTab, setCurrentTransactionsTab] = useState(TRANSACTIONS_TAB[0].tab);

  const filteredRows =
    currentTransactionsTab === 'all'
      ? transactionsPage
      : transactionsPage.filter((row) => row.status === currentTransactionsTab);

  if (transactionsError) {
    return (
      <ErrorNotification error="We're having issues loading your transactions data. Please try again; if the issue persists, please contact support." />
    );
  }

  const TransactionsUI = () => {
    if (metadata == null || metadata?.pagination.total_count === 0) {
      return <NoTransactions />;
    }

    return (
      <WalletTransactionsTable
        transactionsPage={filteredRows}
        metadata={metadata}
        onPageIndexChange={(pageIndex: number) => goToPage(pageIndex)}
      />
    );
  };

  return (
    <div className="border-level-2 mb-20 rounded-lg border bg-white md:mb-0">
      <p className="text-primary p-6 pb-1 font-semibold">Wallet Transactions</p>
      {metadata == null || metadata?.pagination.total_count === 0 ? (
        <div className="border-level-2 border-b pb-4" />
      ) : (
        <UnderlinedTabs
          className="mt-3 pl-6 md:mt-0 md:h-12"
          selectedTab={currentTransactionsTab}
          handleTabChange={(tab) => setCurrentTransactionsTab(tab)}
          tabs={TRANSACTIONS_TAB}
        />
      )}
      <TransactionsUI />
    </div>
  );
}

const NoTransactions = (): ReactElement => {
  return (
    <div className="flex h-[432px] w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/card.svg" alt="Card" />
      <div className="mt-4 flex max-w-[350px] flex-col items-center text-center">
        <h2 className="text-primary mb-3 text-2xl font-semibold">No transactions found</h2>
        <p className="text-secondary text-lg">
          Transactions will display here when you start using card
        </p>
      </div>
    </div>
  );
};
