import { CustomerData } from '@app/@types/customer.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import CustomerContext from '@app/contexts/customerContext';
import useZendeskChat from '@app/hooks/data-scripts/useZendeskChat';
import useChannelPartner from '@app/hooks/useChannelPartner';
import Constants from '@app/utils/constants';
import { useContext } from 'react';

export const ZendeskChat = () => {
  const { customer, isLoading } = useContext(CustomerContext);
  const themeConfiguration = useThemeConfiguration();
  const { name } = useChannelPartner();

  if (
    isLoading ||
    !themeConfiguration?.showZendesk ||
    name === Constants.ChannelPartners.E_CAPITAL_CHANNEL_PARTNER_NAME
  ) {
    return null;
  }

  // small hack to conditionally load the zendesk chat script
  return <ZendeskChatScript customer={customer} />;
};

const ZendeskChatScript = ({ customer }: { customer: CustomerData }) => {
  useZendeskChat({ customer });

  return null;
};
