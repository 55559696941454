import { useTabContext, TabPanelProps } from '@mui/lab';

// Hack to get around the fact that the TabPanel component from @mui/lab mounts/unmounts components
// as tabs become active/inactive, which doesn't work for multi-step forms that need to maintain state

export default function PersistentTabPanel(props: TabPanelProps) {
  const { value: id, children } = props;
  const context = useTabContext();

  if (context === null) {
    throw new TypeError('No TabContext provided');
  }
  const tabId = context.value;

  return (
    <div
      style={{
        display: id === tabId ? 'flex' : 'none',
      }}
    >
      {children}
    </div>
  );
}
