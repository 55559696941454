import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';

export default function EntryButton({ title, className, ...props }: ButtonProps) {
  return (
    <Button
      color="tertiary"
      className={classNames(
        'border-level-2 hover:border-level-3 flex w-full justify-start gap-2 rounded border border-solid p-3 font-medium hover:border-2 hover:bg-transparent hover:p-[11px] focus:bg-transparent focus:shadow-none md:p-4 md:hover:p-[15px]',
        className,
      )}
      {...props}
    >
      {title}
    </Button>
  );
}
