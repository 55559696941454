import Modal, {
  ModalHeader,
  ModalBodyContent,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { bytesToSize } from '@atob-developers/shared/src/utils/fileUpload';
import { faPaperclipVertical, faCloudArrowUp, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import { DropzoneOptions, useDropzone, ErrorCode } from 'react-dropzone';

type DialogDropzoneProps = {
  open: boolean;
  handleClose: () => void;
  options?: Omit<DropzoneOptions, 'onDrop'>;
  handleSubmit: (files: File[]) => void | Promise<void>;
};

function getErrorMessage(errorCode: string, file: File, options?: DropzoneOptions) {
  if (errorCode === ErrorCode.FileTooLarge) {
    return `File ${file.name} is larger than ${bytesToSize(options?.maxSize ?? 0)}`;
  }

  if (errorCode === ErrorCode.FileTooSmall) {
    return `File ${file.name} is smaller than ${bytesToSize(options?.minSize ?? 0)}`;
  }

  if (errorCode === ErrorCode.TooManyFiles) {
    return `Maximum allowed number of files exceeded. Only ${options?.maxFiles} allowed`;
  }

  return 'File type not supported';
}

export default function DialogDropzone({
  open,
  handleClose,
  handleSubmit,
  options,
}: DialogDropzoneProps) {
  const [files, setFiles] = useState<(File & { key: string })[]>([]);
  const { addToast } = useToasts();
  const { isDragActive, isDragReject, getRootProps, getInputProps } = useDropzone({
    ...options,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const errorCode = fileRejections[0].errors[0].code;
        const file = fileRejections[0].file;
        const message = getErrorMessage(errorCode, file, options);
        addToast(message, { appearance: 'error' });
        return;
      }

      if (files.length + acceptedFiles.length > (options?.maxFiles ?? 0)) {
        addToast(`Files limit exceeded. Max number of files is ${options?.maxFiles}.`, {
          appearance: 'error',
        });
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, { key: crypto.randomUUID() }),
      );

      const isMultiple = options?.multiple ?? true;
      if (!isMultiple) {
        setFiles([...newFiles]);
      } else {
        setFiles((previousFiles) => [...previousFiles, ...newFiles]);
      }

      addToast(`${newFiles.map((file) => file.name).join(', ')} added successfully`, {
        appearance: 'success',
      });
    },
  });

  const renderPreviews = () => {
    if (files.length === 0) {
      return null;
    }

    return (
      <div className="grid grid-cols-[repeat(auto-fill,_minmax(96px,_1fr))] gap-4 pt-4">
        {files.map((file) => (
          <div
            key={file.key}
            className="group relative flex max-w-[96px] flex-col items-center gap-1"
          >
            <div className="border-primary flex h-16 w-16 items-center justify-center rounded-lg border">
              <FontAwesomeIcon icon={faPaperclipVertical} className="h-10 w-10" />
            </div>
            <span className="text-primary text-center text-sm">{file.name}</span>
            <button
              type="button"
              className="bg-error-1 absolute right-0 top-0 flex h-8 w-8 -translate-y-1/3 items-center justify-center rounded-full"
              onClick={() => handleDelete(file.key)}
            >
              <FontAwesomeIcon icon={faXmark} className="h-5 w-5 text-white" />
            </button>
          </div>
        ))}
      </div>
    );
  };

  const handleDelete = (key: string) => {
    setFiles((previousFiles) => previousFiles.filter((file) => file.key !== key));
    addToast('File deleted successfully', { appearance: 'info' });
  };

  const onClose = () => {
    handleClose();
    setFiles([]);
  };

  const onSubmit = () => {
    handleSubmit(files);
    setFiles([]);
  };

  return (
    <Modal open={open} toggle={onClose}>
      <ModalHeader title="Upload File" />
      <ModalBodyContent>
        <div
          {...getRootProps({
            className: classNames(
              'h-64 border rounded-md',
              (isDragActive || isDragReject) && 'animate-progress bg-[length:150%_100%]',
              isDragActive && !isDragReject && 'bg-stripes border-primary',
              isDragActive && isDragReject && 'bg-stripes-error border-error-1',
            ),
          })}
        >
          <input {...getInputProps()} />
          <div
            className={classNames(
              'flex flex-col items-center gap-2 pt-6',
              isDragActive && isDragReject && 'text-white',
            )}
          >
            <p className="text-2xl font-medium">Drag and drop a file here or click</p>
            <FontAwesomeIcon icon={faCloudArrowUp} className="h-14 w-14" />
          </div>
        </div>
        {renderPreviews()}
      </ModalBodyContent>
      <ModalFooter>
        <Button size="small" color="alert" onClick={onClose}>
          Cancel
        </Button>
        <Button size="small" disabled={!files.length} onClick={onSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
}
