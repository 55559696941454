import { ErrorNotification } from '@app/components/layout';
import StatusCodes from '@app/constants/http-status-codes';
import useAuth from '@app/hooks/useAuth';
import { Address } from '@app/interfaces/address';
import logger from '@app/utils/datadog-logger';
import { getEnvironment } from '@app/utils/environment';
import { TabContext, TabList } from '@mui/lab';
import { Button, CircularProgress, Tab } from '@mui/material';
import * as Sentry from '@sentry/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import DebitSaveSuccessful from '../PaymentMethods/DebitSaveSuccessful';
import CardLegalTerms from '../StripeElements/CardLegalTerms';
import TermsAndConditions from '../StripeElements/TermsAndConditions';
import BillingAddressTabPanel from './BillingAddressTabPanel';
import CardDetailsTabPanel from './CardDetailsTabPanel';
import TabPanel from './PersistentTabPanel';

const cssWithWidth = (width: string) => ({
  background: '#FFFFFF',
  border: '1px solid #CED7E6',
  boxSizing: 'border-box',
  borderRadius: '4px',
  height: '40px',
  padding: '4px 12px',
  fontSize: '16px',
  margin: '0 0 8px 0',
  width,
});

const SetupDebitCardWithVGS = ({
  payroll,
  onSuccess = () => {},
}: {
  payroll: boolean;
  onSuccess?: () => void;
}): ReactElement => {
  const [form, setForm] = useState(null);
  const [cardDetailsValid, setCardDetailsValid] = useState({
    card_number: false,
    card_exp: false,
    card_cvc: false,
  });
  const areCardDetailsValid = Object.values(cardDetailsValid).every((v) => v);

  const [cardholderName, setCardholderName] = useState('');
  const [address, setAddress] = useState<Address>({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });
  const areBillingDetailsValid = Object.values({
    cardholderName,
    ...address,
    address2: 'not required',
  }).every((v) => v.length !== 0);

  const { getAccessTokenSilently } = useAuth();
  const [response, setResponse] = useState('');
  const [errorMessage, setError] = useState<string | null>(null);
  const [showTerms, setShowTerms] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const { VITE_VGS_VAULT_ID, VITE_VGS_ENVIRONMENT } = getEnvironment();

  const initForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (vgsCollect: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = vgsCollect.init((_: any) => {});
      const cardNumber = form.field('#vgs-card-number', {
        type: 'card-number',
        name: 'card_number',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '1234 5678 8909 8765',
        showCardIcon: true,
        validations: ['required', 'validCardNumber'],
        autoComplete: 'cc-number',
        css: cssWithWidth('100%'),
      });
      const expiry = form.field('#vgs-card-expiry', {
        type: 'card-expiration-date',
        name: 'card_exp',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: 'MM / YY',
        yearLength: '2',
        validations: ['required', 'validCardExpirationDate'],
        autoComplete: 'cc-exp',
        css: cssWithWidth('45%'),
      });
      const cvc = form.field('#vgs-card-cvc', {
        type: 'card-security-code',
        name: 'card_cvc',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '000',
        validations: ['required', 'validCardSecurityCode'],
        css: cssWithWidth('50%'),
      });
      [cardNumber, expiry, cvc].forEach((field) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        field.on('update', (fieldState: any) => {
          setCardDetailsValid((prev) => ({
            ...prev,
            [fieldState.name]: fieldState.isValid,
          }));
        });
      });
      cardNumber.setCVCDependency(cvc);
      setForm(form);
    },
    [],
  );

  const hasLoadedForm = useRef(false);
  useEffect(() => {
    const loadForm = async () => {
      try {
        const vgsCollect = await loadVGSCollect({
          vaultId: VITE_VGS_VAULT_ID as string,
          environment: VITE_VGS_ENVIRONMENT as string,
          version: '2.18.1',
        });
        initForm(vgsCollect);
      } catch (e: unknown) {
        setError(
          'We were unable to load the card details form. Please try again. If the error persists, please contact our support team.',
        );
        Sentry.captureMessage('Error setting up VGS Collect');
      }
    };

    if (!hasLoadedForm.current) {
      hasLoadedForm.current = true;
      loadForm();
    }
  }, [VITE_VGS_VAULT_ID, VITE_VGS_ENVIRONMENT, initForm, showTerms]);

  const [tabIndex, setTabIndex] = useState('card_details');
  const tabClasses = {
    root: 'normal-case items-start p-0 mr-2',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsConfirming(true);
    const authToken = await getAccessTokenSilently();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    form.submit(
      '/vgs/payment_methods',
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: (formValues: any) => {
          return {
            card_number: formValues.card_number,
            card_exp: formValues.card_exp,
            card_cvc: formValues.card_cvc,
            card_address_zip: address.zip,
            cardholder_name: cardholderName,
            address: {
              address1: address.address1,
              address2: address.address2 ?? '',
              city: address.city,
              state: address.state,
              zip: address.zip,
            },
          };
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (status: any, data: any) => {
        setIsConfirming(false);
        if (status !== StatusCodes.CREATED) {
          const defaultError =
            'There was a problem adding your debit card. Please try again later, or if the issue persists, contact support.';
          const [error] = data.errors || [defaultError];
          setError(error);
        } else {
          setResponse(JSON.stringify(data));
          onSuccess();
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => {
        logger.error('Error submitting form to VGS', e);
        setIsConfirming(false);
        setError('The information you entered does not appear to be valid. Please try again.');
      },
    );
  };

  if (response) {
    return (
      <div>
        <DebitSaveSuccessful />
      </div>
    );
  }

  if (showTerms) {
    return <TermsAndConditions onClick={() => setShowTerms(false)} />;
  }

  return (
    <>
      <div>
        <div className="mb-4 flex flex-col overflow-hidden">
          {isConfirming && (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}
          <form id="vgs-collect-form" data-testid="vgs-collect-form" className="w-[300px] flex-col">
            <TabContext value={tabIndex}>
              <TabList onChange={(_, value) => setTabIndex(value)} variant="fullWidth">
                <Tab label="Card details" value="card_details" classes={tabClasses} />
                <Tab
                  label="Billing address"
                  value="billing_address"
                  classes={tabClasses}
                  disabled={!areCardDetailsValid}
                />
              </TabList>
              <TabPanel value="card_details">
                <CardDetailsTabPanel />
              </TabPanel>
              <TabPanel value="billing_address">
                <BillingAddressTabPanel
                  address={address}
                  setAddress={setAddress}
                  cardholderName={cardholderName}
                  setCardholderName={setCardholderName}
                />
              </TabPanel>
            </TabContext>
            {errorMessage && <ErrorNotification className="mt-4" error={errorMessage} />}
            <div className="mt-4 flex h-10 space-x-3">
              {tabIndex === 'card_details' && (
                <Button
                  className="w-full"
                  disabled={!areCardDetailsValid}
                  onClick={() => {
                    setTabIndex('billing_address');
                  }}
                >
                  Next
                </Button>
              )}
              {tabIndex === 'billing_address' && (
                <>
                  <Button
                    className="w-full"
                    color="secondary"
                    onClick={() => {
                      setTabIndex('card_details');
                    }}
                  >
                    Previous Step
                  </Button>
                  <Button
                    className="w-full"
                    disabled={!form || !areBillingDetailsValid || isConfirming}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save Card
                  </Button>
                </>
              )}
            </div>
            <div className="flex text-center">
              <CardLegalTerms payroll={payroll} setShowTerms={setShowTerms} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SetupDebitCardWithVGS;
