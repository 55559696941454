import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ReactElement, useState } from 'react';

import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

const DESCRIPTION_TEXT_HEALTHY =
  'To get all the benefits of the Uber Freight Carrier Card, simply transfer your AtoB account over. Would you like to do that?';
const DESCRIPTION_TEXT_UNHEALTHY =
  'To get all the benefits of the Uber Freight Carrier Card, you’ll need to transfer your AtoB account over. Get started by confirming your interest, and the AtoB Team will reach out to you over email within 24 hours.';
const POSITIVE_BUTTON_TEXT_HEALTHY = 'Yes, transfer account';
const POSITIVE_BUTTON_TEXT_UNHEALTHY = "Yes, I'm interested";
const NEGATIVE_BUTTON_TEXT_HEALTHY = 'No, do not transfer account now';
const NEGATIVE_BUTTON_TEXT_UNHEALTHY = 'No, I’m not interested';

// Define the prop types for HealthyView
type HealthyViewProps = {
  onPositiveButtonClick: () => void;
  onNegativeButtonClick: () => void;
};

const LandingView = ({
  onPositiveButtonClick,
  onNegativeButtonClick,
}: HealthyViewProps): ReactElement => {
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const showHealthyState = location.pathname.endsWith('healthy');

  return (
    <div className="font-mTFF">
      <h1 className="mb-5 px-6 text-[28px] font-bold leading-[45px]">
        Do you want to convert your existing AtoB fuel card account?
      </h1>
      <p className="mb-9 px-6">
        {showHealthyState ? DESCRIPTION_TEXT_HEALTHY : DESCRIPTION_TEXT_UNHEALTHY}
      </p>

      <div className="px-6">
        <FormGroup className="mb-6">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                sx={{
                  '&': {
                    ...tw`bg-white rounded-none border-[3px] border-solid border-[rgb(107,107,107)] w-5 h-5`,
                    'input': {
                      ...tw`w-5 h-5`,
                    },
                    '&.Mui-checked': {
                      ...tw`bg-black border-black`,
                      svg: {
                        ...tw`block`,
                      },
                    },
                    'svg': {
                      ...tw`hidden`,
                    },
                  },
                }}
                checkedIcon={
                  <FontAwesomeIcon icon={faCheck} size="xl" className="h-3.5 w-3.5 text-white" />
                }
              />
            }
            classes={{ label: 'text-base font-medium text-black font-mTTF' }}
            label="I have read the details of this account transfer at the bottom of this page"
          />
        </FormGroup>

        <div className="mb-2">
          <Button
            fullWidth
            disabled={!checked}
            className="bg-black text-lg font-medium leading-6 hover:bg-[rgb(51,51,51)] disabled:cursor-not-allowed disabled:bg-[rgb(246,246,246)] disabled:text-[rgb(175,175,175)] disabled:opacity-100"
            onClick={onPositiveButtonClick}
          >
            {showHealthyState ? POSITIVE_BUTTON_TEXT_HEALTHY : POSITIVE_BUTTON_TEXT_UNHEALTHY}
          </Button>
        </div>
        <div className="mb-12">
          <Button
            fullWidth
            className="bg-[rgb(238,238,238)] text-lg font-medium leading-6 text-black hover:bg-[rgb(226,226,226)]"
            onClick={onNegativeButtonClick}
          >
            {showHealthyState ? NEGATIVE_BUTTON_TEXT_HEALTHY : NEGATIVE_BUTTON_TEXT_UNHEALTHY}
          </Button>
        </div>
      </div>

      <h2 className="mb-8 px-6 text-2xl font-bold">What a transfer means for you</h2>

      <ul className="px-3">
        <li className="bg-white p-4">
          <div className="border-b border-[rgba(0,0,0,0.04)]">
            <p className="text-base font-medium leading-5">Better fuel discounts</p>
            <p className="text-sm">
              You’ll save up to 84¢ per gallon with your Uber Freight Carrier Card instead of the
              current flat 2-3¢ discount with AtoB. Use the Fuel Finder in the Uber Freight app to
              see discounts nearby.
            </p>
          </div>
        </li>

        <li className="bg-white p-4">
          <div className="border-b border-[rgba(0,0,0,0.04)]">
            <p className="text-base font-medium leading-5">New ways to contact support</p>
            <p className="text-sm">
              Get dedicated support at +1 831 704-9242 or ufcarriercard@atob.com.
            </p>
          </div>
        </li>

        <li className="bg-white p-4">
          <div className="border-b border-[rgba(0,0,0,0.04)]">
            <p className="text-base font-medium leading-5">Reduced access to premium features</p>
            <p className="text-sm">
              If you are an AtoB Premium customer, you’ll be downgraded to the Basic tier and lose
              all premium features except SMS Unlock.
            </p>
          </div>
        </li>

        <li className="bg-white p-4">
          <div>
            <p className="text-base font-medium leading-5">Manage spend all in one place</p>
            <p className="text-sm">
              You’ll no longer manage spend in AtoB, and instead you’ll manage from within Uber
              Freight app or the web portal.
            </p>
          </div>
        </li>
      </ul>

      <p className="my-6 px-6">
        If you have other questions, please reach out to ufcarriercard@atob.com.
      </p>
    </div>
  );
};

export default LandingView;
