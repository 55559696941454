import Header from '@app/components/Navigation/Header';
import AutoTopUpSidebar from '@app/components/Prepaid/AutoTopUps/AutoTopUpSidebar';
import { useAutoTopUpData } from '@app/components/Prepaid/AutoTopUps/use-auto-topup-data';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { StatusProps } from './WalletOverview';

const WalletPageHeader = ({
  balance,
  isTrusted,
  status,
}: Pick<StatusProps, 'balance' | 'isTrusted' | 'status'>) => {
  const canModifyFunds = status === 'enrolled' && balance !== null;
  const { autoTopUp, isLoading, autoTopUpError, refreshAutoTopUp } = useAutoTopUpData();
  const [autoTopUpSidebarOpen, setAutoTopUpSidebarOpen] = useState(false);
  const allowedToFund = useIsAllowedToFund();

  const closeAutoTopUpSidebar = () => {
    setAutoTopUpSidebarOpen(false);
    setTimeout(() => {
      refreshAutoTopUp();
    }, 1000);
  };

  const menuData = useMemo(
    () => [
      {
        id: 'autoDeposit',
        text: 'Auto-Deposit',
        icon: <FontAwesomeIcon icon={faRefresh} className="text-base" />,
        onClick: () => setAutoTopUpSidebarOpen(true),
        disabled: () => !allowedToFund,
        order: () => 1,
      },
    ],
    [allowedToFund],
  );

  const menuItems = useMemo(
    () =>
      menuData
        .filter((item) => item.id === 'autoDeposit' || isTrusted)
        .map((item) => ({
          value: (
            <>
              {item.icon}
              {item.text}
            </>
          ),
          onClick: item.onClick,
          disabled: item.disabled,
          order: item.order(),
        })),
    [isTrusted, menuData],
  );

  useExtendMobileHeaderContextMenu(...menuItems);

  return (
    <Header
      className="md:-mb-6"
      title={<p className="text-primary text-lg font-semibold md:text-3xl">Wallet</p>}
      rightContent={
        <div className="flex items-center justify-end gap-2">
          <div className="flex items-center justify-between p-5 pr-0">
            {canModifyFunds && (
              <div className="flex gap-2">
                {menuData
                  .filter((item) => item.id === 'autoDeposit' || isTrusted)
                  .map((item) => (
                    <Button
                      className="p-3 text-sm"
                      color="secondary"
                      key={item.id}
                      onClick={item.onClick}
                      disabled={item.disabled()}
                      startIcon={item.icon}
                    >
                      {item.text}
                    </Button>
                  ))}
              </div>
            )}
            <AutoTopUpSidebar
              autoTopUp={autoTopUp}
              isLoading={isLoading}
              autoTopUpError={autoTopUpError}
              refreshAutoTopUp={refreshAutoTopUp}
              isOpen={autoTopUpSidebarOpen}
              toggle={closeAutoTopUpSidebar}
            />
          </div>
        </div>
      }
    />
  );
};

export default WalletPageHeader;
