import { ReactElement } from 'react';

const SoundsGoodView = (): ReactElement => {
  return (
    <div className="font-mTFF space-y-8 px-6">
      <h1 className="text-[40px] font-bold leading-[52px]">We will not transfer your account</h1>
      <p>
        We will not transfer your account and we will not enroll you for the Uber Freight Carrier
        Card.
      </p>
      <p>If you change your mind, please reach out to ufcarriercard@atob.com.</p>
    </div>
  );
};

export default SoundsGoodView;
