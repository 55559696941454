import { ChargeEventData } from '@app/@types/charge_events.types';
import { CellPlaceholder } from '@app/components/TableV2/Cell';
import Merchant from '@app/components/Transactions/Merchant';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import {
  formatCurrency,
  formatDateLocale,
  formatTime,
} from '@atob-developers/shared/src/utils/formatters';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import { getStatusesHelper } from './utils';

type MobileCellProps = {
  row: ChargeEventData;
  onClick?: () => void;
};

function Price({ row }: MobileCellProps) {
  const { gross_amount_cents, net_amount_cents } = row;
  const { isPending, hasSaved, hasMissed, hasDeclined } = getStatusesHelper(row);

  const formattedGrossAmount = formatCurrency({ value: gross_amount_cents });
  const formattedNetAmount = formatCurrency({ value: net_amount_cents ?? 0 });

  if (isPending) {
    return (
      <p className="text-tertiary truncate text-xs font-medium leading-5">{formattedGrossAmount}</p>
    );
  }

  if (hasSaved) {
    return (
      <p className="text-primary truncate text-xs font-medium leading-5">{formattedNetAmount}</p>
    );
  }

  if (hasMissed || !hasDeclined) {
    return (
      <p className="text-primary truncate text-xs font-medium leading-5">{formattedGrossAmount}</p>
    );
  }

  if (hasDeclined) {
    return (
      <p className="text-tertiary truncate text-xs font-medium leading-5 line-through">
        -{formattedGrossAmount}
      </p>
    );
  }

  return <CellPlaceholder />;
}

function AuthorizedDate({ row }: MobileCellProps) {
  const date = new Date(row.authorized_at);

  return (
    <p className="text-secondary truncate text-[10px] leading-4">
      <span>{formatDateLocale(date)}</span> <span>{formatTime(date)}</span>
    </p>
  );
}

function FeeAndSavings({ row }: MobileCellProps) {
  const { discount_amount_cents, potential_savings, oon_fee_amount } = row;
  const { hasSaved, hasMissed, hasDeclined, totalSavings } = getStatusesHelper(row);
  const [oonFees] = useFeatureFlags('oon_fees');

  const renderSavingsChip = () => {
    if (hasSaved) {
      return (
        <Chip
          size="small"
          color="green"
          label={`You saved ${formatCurrency({ value: discount_amount_cents ?? 0 })}`}
        />
      );
    }

    if (hasMissed) {
      return (
        <Chip
          size="small"
          color="orange"
          label={`You missed ${formatCurrency({
            value: totalSavings,
          })} • ${potential_savings?.distance_in_miles} away`}
        />
      );
    }

    return <div />;
  };

  const renderFeeChip = () => {
    if (!oonFees) {
      return null;
    }

    if (oon_fee_amount?.cents == null || oon_fee_amount.cents == 0) {
      return <Chip size="small" color={hasSaved ? 'green' : 'grey'} label="No fee" />;
    }

    return (
      <Chip
        size="small"
        color={hasMissed ? 'orange' : 'grey'}
        label={`+ ${formatCurrency({ value: oon_fee_amount?.cents })} fee`}
      />
    );
  };

  if (hasDeclined) {
    return null;
  }

  return (
    <div className="flex items-center justify-between">
      {renderSavingsChip()}
      {renderFeeChip()}
    </div>
  );
}

export function MobileCell({ row, onClick }: MobileCellProps) {
  const { hasSaved, hasMissed, isDisputed } = getStatusesHelper(row);

  return (
    <div
      onClick={onClick}
      className={classNames(
        'border-b-level-2 flex flex-col gap-1.5 border-b px-4 py-3',
        !isDisputed && hasSaved && 'bg-accent-12',
        !isDisputed && hasMissed && 'bg-warning-2',
        !isDisputed && !hasSaved && !hasMissed && 'bg-level-0',
        isDisputed && 'bg-error-2',
      )}
    >
      <div className="flex items-center justify-between gap-3">
        <Merchant row={row} />
        <div className="flex flex-col items-end">
          <Price row={row} />
          <AuthorizedDate row={row} />
        </div>
      </div>
      <FeeAndSavings row={row} />
    </div>
  );
}
