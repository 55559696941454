import Constants from '@app/utils/constants';

import axios from 'axios';
import { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CongratulationsView from './CongratulationsView';
import InterestedView from './InterestedView';
import LandingView from './LandingView';
import SoundsGoodView from './SoundsGoodView';

// Define the possible views
type ViewType = 'HEALTHY' | 'CONGRATS' | 'INTERESTED' | 'SOUNDS_GOOD';

const UberFreightConversionLandingPage = (): ReactElement => {
  const [currentView, setCurrentView] = useState<ViewType>('HEALTHY');
  const location = useLocation();
  const showHealthyState = location.pathname.endsWith('healthy');

  const onPositiveButtonClick = async () => {
    if (showHealthyState) {
      await axios.put(`/customer/switch_channel_partner`, {
        channel_partner: Constants.ChannelPartners.UBER_FREIGHT_CHANNEL_PARTNER_NAME,
      });
      setCurrentView('CONGRATS');
    } else {
      await axios.post(`/customer/express_interest_channel_partner`);
      setCurrentView('INTERESTED');
    }
  };
  const onNegativeButtonClick = () => {
    setCurrentView('SOUNDS_GOOD');
  };
  return (
    <div>
      {currentView === 'HEALTHY' && (
        <LandingView
          onPositiveButtonClick={onPositiveButtonClick}
          onNegativeButtonClick={onNegativeButtonClick}
        />
      )}
      {currentView === 'CONGRATS' && <CongratulationsView />}
      {currentView === 'INTERESTED' && <InterestedView />}
      {currentView === 'SOUNDS_GOOD' && <SoundsGoodView />}
    </div>
  );
};

export default UberFreightConversionLandingPage;
