import { ChargeEventData } from '@app/@types/charge_events.types';
import { ThemeIdentifier } from '@app/app/ThemeProvider/themeConfigurations';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import Header from '@app/components/Navigation/Header';
import { Loading } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import {
  ChargeEventsContext,
  useSetTransactionId,
} from '@app/contexts/ChargeEventsContextComponent';
import {
  useCustomNavButtonForPage,
  useExtendMobileHeaderContextMenu,
  useSetNextNavButton,
  useSetPrevNavButton,
} from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { useChargeEventQuery } from '@app/hooks/query/useChargeEventQuery';
import useChargeEvents from '@app/hooks/useChargeEvents';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faEllipsisVertical,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { matchRoutes, RouteObject, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChargeEventDetails } from './ChargeEventDetails';
import { CreateDisputeModal } from './CreateDisputeModal';
import HeaderTitle from './HeaderTitle';
import { generateBulkActionList } from './SpendRestrictionActions';

const TransactionDetail = (): ReactElement => {
  const { transactionId } = useParams<{ transactionId: string }>();
  const { data, isLoading, error, mutate } = useChargeEventQuery(transactionId);

  const navigate = useNavigate();

  const navigateUp = useCallback(() => {
    if (window.history?.state.idx === 0) {
      navigate('..');
    } else {
      navigate(-1);
    }
  }, [navigate]);

  const chargeEvent = data?.data;

  const navBackButton = useMemo(
    () => ({
      icon: faArrowLeft,
      disabled: false,
      onClick: navigateUp,
    }),
    [navigateUp],
  );

  useCustomNavButtonForPage(navBackButton);
  useSetTransactionId(transactionId);
  const [isDisputeModalOpen, setIsDisputeModalOpen] = useState(false);
  const openDisputeModal = useCallback(() => setIsDisputeModalOpen(true), []);

  const theme = useTheme();
  const { isMobile } = useWindowWidth();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const title = (isSmall ? '' : 'Transaction ') + 'Details';

  if (isLoading) {
    return (
      <PageContentWrapper
        header={
          <Header
            title={title}
            onBack={isMobile ? navigateUp : null}
            breadcrumbs={<Breadcrumb onBack={navigateUp} />}
          />
        }
      >
        <Loading />
      </PageContentWrapper>
    );
  }

  if (error) {
    return (
      <PageContentWrapper
        header={
          <Header
            title={title}
            onBack={isMobile ? navigateUp : null}
            breadcrumbs={<Breadcrumb onBack={navigateUp} />}
          />
        }
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      </PageContentWrapper>
    );
  }

  if (!chargeEvent) {
    return (
      <PageContentWrapper
        header={
          <Header
            title={title}
            onBack={isMobile ? navigateUp : null}
            breadcrumbs={<Breadcrumb onBack={navigateUp} />}
          />
        }
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Transaction unavailable
        </Alert>
      </PageContentWrapper>
    );
  }

  return (
    <PageContentWrapper
      baseClassName="md:!gap-6"
      header={
        <Header
          title={isMobile ? title : <HeaderTitle chargeEvent={chargeEvent} />}
          onBack={isMobile ? navigateUp : null}
          breadcrumbs={<Breadcrumb onBack={navigateUp} />}
          rightContent={
            <MerchantBlockingMenu chargeEvent={chargeEvent} onOpenDisputeModal={openDisputeModal} />
          }
        />
      }
    >
      <ChargeEventDetails chargeEvent={chargeEvent} />
      <CreateDisputeModal
        chargeEventID={chargeEvent.id}
        open={isDisputeModalOpen}
        toggle={() => setIsDisputeModalOpen((p) => !p)}
        refreshChargeEvent={mutate}
      />
    </PageContentWrapper>
  );
};

const Breadcrumb = ({ onBack }: { onBack: () => void }) => {
  const { id } = useThemeConfiguration();
  const location = useLocation();

  const routes: RouteObject[] = [
    { path: '/transactions/:id', element: 'Transactions' },
    { path: '/telematics/suspicious-activities/:id', element: 'Suspicious Activities' },
  ];

  const match = matchRoutes(routes, location);

  if (id === ThemeIdentifier.TRIUMPH || !match?.[0].route.element) {
    return null;
  }

  return (
    <Breadcrumbs className="hidden md:block">
      <span className="text-secondary cursor-pointer text-sm font-medium" onClick={onBack}>
        {match[0].route.element}
      </span>
      <span className="text-primary text-sm font-semibold">Details</span>
    </Breadcrumbs>
  );
};

const MerchantBlockingMenu = ({
  chargeEvent,
  onOpenDisputeModal,
}: {
  chargeEvent: ChargeEventData;
  onOpenDisputeModal: () => void;
}): ReactElement | null => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selfServeDisputes] = useFeatureFlags('self_serve_disputes');

  const { pathname } = useLocation();

  const [spendRestrictions, allowMerchantRestrictions] = useProduct(
    'spend_restrictions',
    'transactions_allow_restrict_merchant',
  );
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [actions, menuItems] = useMemo(() => {
    const actions = generateBulkActionList(chargeEvent, allowMerchantRestrictions, addToast);
    const menuItems = actions
      .map((action, idx) => ({
        value: action.text,
        onClick: action.onClick,
        disabled: () => false,
        order: idx,
      }))
      .filter((_) => spendRestrictions);
    if (
      selfServeDisputes &&
      dayjs(chargeEvent.transacted_at).isAfter(dayjs().subtract(100, 'days')) &&
      chargeEvent.dispute_state == null
    ) {
      actions.push({
        text: 'Dispute',
        onClick: onOpenDisputeModal,
      });
      menuItems.push({
        value: 'Dispute',
        onClick: onOpenDisputeModal,
        disabled: () => false,
        order: 5,
      });
    }
    return [actions, menuItems];
  }, [
    addToast,
    allowMerchantRestrictions,
    chargeEvent,
    onOpenDisputeModal,
    selfServeDisputes,
    spendRestrictions,
  ]);

  const url = pathname.includes('suspicious') ? '/charge_events/suspicious' : '/charge_events';

  useExtendMobileHeaderContextMenu(...menuItems);
  const { nonURLQueryParams } = useContext(ChargeEventsContext);
  const { data: chargeEventsList, isLoading } = useChargeEvents(url, nonURLQueryParams);

  const chargeEvents = useMemo(() => chargeEventsList?.data ?? [], [chargeEventsList?.data]);
  const { transactionId } = useContext(ChargeEventsContext);
  const transactionIdx = chargeEvents.findIndex((event) => event.id === transactionId);

  const backButton = useMemo(() => {
    const disabled = isLoading || transactionIdx <= 0;
    return {
      disabled,
      icon: faAngleLeft,
      onClick: disabled
        ? () => {}
        : () => navigate('../' + chargeEvents[transactionIdx - 1].id, { replace: true }),
    };
  }, [chargeEvents, isLoading, navigate, transactionIdx]);

  const nextButton = useMemo(() => {
    const disabled =
      isLoading || transactionIdx === -1 || transactionIdx === chargeEvents.length - 1;
    return {
      disabled,
      icon: faAngleRight,
      onClick: disabled
        ? () => {}
        : () => navigate('../' + chargeEvents[transactionIdx + 1].id, { replace: true }),
    };
  }, [chargeEvents, isLoading, navigate, transactionIdx]);

  useSetPrevNavButton(backButton);
  useSetNextNavButton(nextButton);

  if (!spendRestrictions) {
    return null;
  }

  return (
    <div className="hidden gap-2 md:flex">
      <Button
        color="secondary"
        disabled={backButton.disabled}
        onClick={backButton.onClick}
        className="h-12 w-12 !min-w-0 !p-2"
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
      <Button
        color="secondary"
        disabled={nextButton.disabled}
        onClick={nextButton.onClick}
        className="h-12 w-12 !min-w-0 !p-2"
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </Button>
      <Button
        color="secondary"
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        className="h-12 w-12 !min-w-0 !p-2"
      >
        <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setMenuAnchorEl(null);
              action.onClick();
            }}
          >
            {action.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TransactionDetail;
