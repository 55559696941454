import BankAccountIcon from '@app/assets/images/svg/bank_account_icon.svg';
import PaypalIcon from '@app/assets/images/svg/paypal_icon.svg';
import VenmoIcon from '@app/assets/images/svg/venmo_icon.svg';
import VisaIcon from '@app/assets/images/svg/visa_icon.svg';
import ZelleIcon from '@app/assets/images/svg/zelle_icon.svg';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import RadioSelector from '@app/components/RadioSelector/RadioSelector';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, RadioGroup } from '@mui/material';
import {
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mutate } from 'swr';
import AddRecipientModal from '../../PayNow/AddRecipientModal';
import EditRecipientModal from '../../PayNow/EditRecipientModal';
import { addRecipient } from '../../WalletPaymentMethods';
import {
  RecipientData,
  recipientDataToNewRecipient,
  RecipientToCreate,
} from '../../transfer.types';
import { AddInstantPayoutMethodRecipientModal } from '../Modals/AddInstantPayoutMethodRecipientModal';
import {
  accountNameMapping,
  addInstantPayoutMethodRecipient,
  editRecipient,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
} from '../utils';

const addRecipientAccount = async (recipient: RecipientToCreate) => {
  await addRecipient(recipient);

  await mutate({ url: '/treasury/recipients' });
};

const DestinationItemWrapper = ({
  name,
  icon,
  children,
}: PropsWithChildren<{
  name: string | ReactElement;
  icon: ReactElement;
}>) => {
  return (
    <div className="flex w-full flex-grow items-center gap-2">
      <div className="flex w-full flex-wrap gap-2">
        <div className="text-primary flex items-center gap-2 text-base">
          {icon}
          <span>{name}</span>
        </div>
        {children}
      </div>
    </div>
  );
};

const InstantDestinationItem = ({
  destination,
  icon,
}: {
  destination: NormalizedDestination;
  icon: ReactElement;
}) => {
  return (
    <DestinationItemWrapper name={destination.name} icon={icon}>
      {destination.alternateName && (
        <Chip color="grey" size="small" label={destination.alternateName} />
      )}
    </DestinationItemWrapper>
  );
};

const iconMapping = {
  debit_card: VisaIcon,
  zelle_account: ZelleIcon,
  bank_account: BankAccountIcon,
  paypal_account: PaypalIcon,
  venmo_account: VenmoIcon,
};

const DestinationItem = ({
  destinationId,
  availableDestinations,
  selectedMethod,
}: {
  destinationId: string;
  availableDestinations: NormalizedDestination[];
  selectedMethod: PaymentMethodType | null;
}) => {
  const destination = availableDestinations.find((elem) => elem.id === destinationId);
  if (!destination) return null;

  if (destination.type !== 'debit_card') {
    return (
      <InstantDestinationItem
        destination={destination}
        icon={<img src={iconMapping[destination.type]} />}
      />
    );
  }

  return (
    <DestinationItemWrapper
      icon={<img src={iconMapping[destination.type]} alt={destination.type} />}
      name={<>••••&nbsp;{destination.lastFour}</>}
    >
      <Chip
        color="grey"
        size="small"
        label={
          destination.name + (destination.alternateName ? ` (${destination.alternateName})` : '')
        }
      />
      {selectedMethod == 'us_domestic_wire' && destination.needsAddress && (
        <Chip color="orange" size="small" label="Address required" />
      )}
    </DestinationItemWrapper>
  );
};

export const DestinationSelector = ({
  selectedDestination,
  availableDestinations,
  loadingDestinations,
  selectedMethod,
  setSelectedDestination,
  transferKind,
  recipients,
}: {
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination | null;
  selectedMethod: PaymentMethodType | null;
  setSelectedDestination: (value: SetStateAction<NormalizedDestination | null>) => void;
  loadingDestinations: boolean;
  transferKind: TransferKind;
  recipients: RecipientData[];
}) => {
  const [showAddDestinationModal, setShowAddDestinationModal] = useState(false);
  const [showUpdateRecipient, setShowUpdateRecipient] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  const selectedRecipient = recipients.find(
    (recipient) => recipient.id === selectedDestination?.id,
  );

  useEffect(() => {
    if (selectedMethod == null) {
      return;
    }
    divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (!loadingDestinations && availableDestinations.length == 0) {
      setShowAddDestinationModal(true);
    }
  }, [availableDestinations.length, loadingDestinations, selectedMethod]);

  return (
    <div className="flex flex-col" ref={divRef}>
      <h4 className="mb-2 font-medium">{selectedMethod === 'debit' ? 'Debit card' : 'Account'}</h4>
      {selectedMethod && availableDestinations.length === 0 ? (
        <p className="text-secondary text-sm">{`There is no ${accountNameMapping[selectedMethod]} attached to your AtoB account yet.`}</p>
      ) : (
        <RadioGroup className="gap-2">
          {availableDestinations.map((destination) => {
            const id = destination.id;
            return (
              <RadioSelector
                key={id}
                value={id}
                label={
                  <DestinationItem
                    selectedMethod={selectedMethod}
                    destinationId={id}
                    availableDestinations={availableDestinations}
                  />
                }
                onChange={() => {
                  if (id === 'ignore') return;
                  const matchedDestination = availableDestinations.find((dest) => dest.id === id);
                  if (matchedDestination) {
                    setSelectedDestination(matchedDestination);
                  }
                }}
                labelPlacement="start"
                checked={selectedDestination?.id === id}
                disabled={loadingDestinations || !selectedMethod}
              />
            );
          })}
        </RadioGroup>
      )}
      <Button
        size="small"
        className="mt-3"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => setShowAddDestinationModal(true)}
        disabled={selectedMethod == null}
      >
        <span className="ml-2 text-sm">
          Add {selectedMethod && accountNameMapping[selectedMethod]}
        </span>
      </Button>
      {selectedMethod == 'us_domestic_wire' && selectedDestination?.needsAddress && (
        <div className="mt-2 text-sm">
          The account requires an address to support wire transfers.{' '}
          <button className="underline" onClick={() => setShowUpdateRecipient(true)}>
            Enter address
          </button>
        </div>
      )}
      <DebitCardModal
        isActive={
          showAddDestinationModal && transferKind === 'own_transfer' && selectedMethod == 'debit'
        }
        setIsActive={() => setShowAddDestinationModal(false)}
        onSuccess={() => {
          mutate(PAYMENT_METHOD_QUERY_KEY);
          setShowAddDestinationModal(false);
        }}
      />
      <AddRecipientModal
        reset={() => setShowAddDestinationModal(false)}
        onAddRecipient={async (recipient) => {
          await addRecipientAccount(recipient);
          setShowAddDestinationModal(false);
        }}
        isOwned={transferKind === 'own_transfer' ? true : false}
        open={showAddDestinationModal && ['ach', 'us_domestic_wire'].includes(selectedMethod || '')}
      />
      {['zelle', 'paypal', 'venmo'].map((type) => (
        <AddInstantPayoutMethodRecipientModal
          reset={() => setShowAddDestinationModal(false)}
          open={showAddDestinationModal && selectedMethod === type}
          onAddInstantPayoutMethodRecipient={async (recipient) => {
            await addInstantPayoutMethodRecipient(recipient);
            setShowAddDestinationModal(false);
          }}
          type={type as 'zelle' | 'paypal' | 'venmo'}
          key={type}
        />
      ))}
      {selectedRecipient && (
        <EditRecipientModal
          open={showUpdateRecipient}
          recipient={recipientDataToNewRecipient(selectedRecipient)}
          onEditRecipient={async (recipient) => {
            await editRecipient(recipient);
            setShowUpdateRecipient(false);
          }}
          reset={() => setShowUpdateRecipient(false)}
        />
      )}
    </div>
  );
};
