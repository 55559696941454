import { ChargeEventData } from '@app/@types/charge_events.types';
import { BrandLogo } from '@app/components/FuelListings/Logos/BrandLogo';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import {
  formatCurrency,
  formatDateLocale,
  formatTime,
} from '@atob-developers/shared/src/utils/formatters';
import { formatCurrencyDollarOrCents } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { Button, Chip } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getStatusesHelper } from '../Transactions/utils';

function AmountCard({ data }: { data: ChargeEventData }) {
  const [oonFees] = useFeatureFlags('oon_fees');
  const {
    authorized_at,
    net_unit_price,
    net_amount_cents,
    gross_amount_cents,
    decline_reason,
    oon_fee_amount,
  } = data;
  const { hasSaved, hasMissed, isPending, hasDeclined } = getStatusesHelper(data);

  const date = new Date(authorized_at);

  const amount = formatCurrency({ value: hasSaved ? net_amount_cents ?? 0 : gross_amount_cents });

  const renderChip = () => {
    if (hasSaved || hasMissed) {
      return <Chip size="small" color="green" label="Approved" />;
    }

    if (isPending) {
      return <Chip size="small" color="grey" label="Pending" />;
    }

    if (hasDeclined && decline_reason != null) {
      return <Chip size="small" color="red" label={`Declined: ${decline_reason}`} />;
    }

    return null;
  };

  const renderFee = () => {
    if (!oonFees) {
      return null;
    }

    if (oon_fee_amount?.cents == null || oon_fee_amount?.cents == 0) {
      return (
        <span className="text-accent-10 truncate text-xs leading-5 md:text-sm">
          No transaction fee
        </span>
      );
    }
    const fee = `+ ${formatCurrency({
      value: oon_fee_amount.cents,
    })} transaction fee`;

    if (hasMissed || isPending) {
      return <span className="text-secondary truncate text-xs leading-5 md:text-sm">{fee}</span>;
    }

    return null;
  };

  return (
    <RoundedCard
      className={classNames((!hasMissed || !data.potential_savings?.station_name) && 'flex-1')}
    >
      <RoundedCardTitle
        title="Amount"
        shortDescription={`${formatDateLocale(date)} ${formatTime(date)}`}
      />
      <RoundedCardCore>
        <div className="flex w-full flex-col gap-1">
          {!hasDeclined && !isPending && (
            <p className="text-secondary text-xs leading-5 md:text-sm">
              {formatCurrency({
                value: net_unit_price?.cents ?? 0,
              })}{' '}
              per gal
            </p>
          )}
          <div className="flex w-full items-end justify-between gap-4 overflow-hidden">
            <div className="flex items-baseline gap-2 overflow-hidden md:gap-3">
              <span
                className={classNames(
                  'text-primary text-xl font-semibold tracking-[-0.5px] md:text-2xl md:tracking-[-0.6px]',
                  hasDeclined && 'line-through',
                )}
              >
                {amount}
              </span>
              {renderFee()}
            </div>
            <div className="max-w-full overflow-hidden">{renderChip()}</div>
          </div>
        </div>
      </RoundedCardCore>
    </RoundedCard>
  );
}

function SavingsCard({ data }: { data: ChargeEventData }) {
  const [oonFees] = useFeatureFlags('oon_fees');
  const navigate = useNavigate();

  const { discount_unit_price, discount_amount_cents, potential_savings } = data;
  const { hasSaved, hasMissed, isPending, hasDeclined, totalSavings } = getStatusesHelper(data);

  if (isPending || hasDeclined || (!hasSaved && !hasMissed)) {
    return null;
  }
  return (
    <RoundedCard className="flex-1">
      <RoundedCardTitle title="AtoB Savings" />
      <div className="flex flex-col lg:flex-row">
        <RoundedCardCore
          className={hasMissed ? 'shrink-0 grow-0 border-b lg:border-b-0 lg:border-r' : ''}
        >
          <div className="flex h-full w-full flex-col gap-1">
            <p className="text-secondary text-xs leading-5 md:text-sm">
              {formatCurrencyDollarOrCents(
                hasSaved
                  ? discount_unit_price?.cents ?? 0
                  : potential_savings?.savings_amount_in_cents ?? 0,
              )}{' '}
              per gal
            </p>
            <span
              className={classNames(
                'whitespace-nowrap text-xl font-semibold tracking-[-0.5px] md:text-2xl md:tracking-[-0.6px]',
                hasSaved ? 'text-accent-11' : 'text-warning-1',
              )}
            >
              {hasSaved ? (
                <>{formatCurrency({ value: discount_amount_cents ?? 0 })} saved</>
              ) : (
                <>{formatCurrency({ value: totalSavings })} missed</>
              )}
            </span>
          </div>
        </RoundedCardCore>
        {hasMissed && potential_savings?.station_name && (
          <RoundedCardCore>
            <div className="w-full space-y-1">
              <div className="flex items-center">
                <span className="text-secondary text-xs leading-5 md:text-sm">{`${potential_savings.distance_in_miles} away`}</span>
                {oonFees && <Chip size="small" color="green" label="No fee" className="ml-auto" />}
              </div>
              <div className="flex items-end justify-between gap-1.5">
                <div className="flex shrink-0 items-center gap-1.5">
                  <BrandLogo border rounded logoURL={potential_savings.logo_url} size={40} />
                  <span className="text-primary text-xl font-semibold tracking-[-0.5px] md:text-2xl md:tracking-[-0.6px]">
                    {potential_savings.station_name}
                  </span>
                </div>
                <Link
                  to={`/fuel-map?address=${encodeURIComponent(
                    potential_savings?.station_location ?? '',
                  )}`}
                  className="text-secondary hidden cursor-pointer truncate text-sm font-medium lg:block"
                >
                  View on Fuel Map
                </Link>
              </div>
            </div>
            <Button
              fullWidth
              onClick={() =>
                navigate(
                  `/fuel-map?address=${encodeURIComponent(
                    potential_savings?.station_location ?? '',
                  )}`,
                )
              }
              color="secondary"
              size="small"
              className="lg:hidden"
            >
              View on Fuel Map
            </Button>
          </RoundedCardCore>
        )}
      </div>
    </RoundedCard>
  );
}

function TransactDisputeBanner({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement | null {
  const disputeRequest = chargeEvent.transact_dispute_request;
  if (disputeRequest == null) {
    return null;
  }

  const content =
    disputeRequest.state === 'submitted'
      ? 'You are awaiting the results of your dispute'
      : 'This dispute is closed';

  return <Alert title="Transaction disputed" content={content} severity="error" />;
}

export default function ChargeEventDetailsBar({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement {
  return (
    <>
      <TransactDisputeBanner chargeEvent={chargeEvent} />
      <div className="flex flex-col gap-4 md:gap-6 xl:flex-row">
        <AmountCard data={chargeEvent} />
        <SavingsCard data={chargeEvent} />
      </div>
    </>
  );
}
