import { ReactElement } from 'react';

const ZERO = '$0.00';

const WalletBalance = ({ balance }: { balance?: string | null }): ReactElement => {
  const balanceValueSplit = balance?.split('.');
  return (
    <div className="flex flex-col justify-between">
      <h2 className="text-secondary leading-12 font-regular text-sm">Available</h2>
      <div className="mt-1 font-semibold">
        {balance === null ? (
          'Pending'
        ) : (
          <>
            <span className="text-primary text-2xl">{balanceValueSplit?.[0]}</span>
            {balance !== ZERO && (
              <span className="text-tertiary text-lg">.{balanceValueSplit?.[1]}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WalletBalance;
