import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { ReactElement } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

export default function FuelDiscountPromotionalBanner(): ReactElement | null {
  const [showBanner] = useFeatureFlags('new_cx_discount_promo');
  const customer = useCustomer();

  if (!showBanner) {
    return null;
  }

  const createdDate = new Date(customer.created_at);
  createdDate.setDate(createdDate.getDate() + 60);

  const month = createdDate.getMonth() + 1;
  const day = createdDate.getDate();
  const formattedDate = `${month}/${day}`;

  return (
    <NewFeatureBanner bannerKey="fuel_discount_promotional" variant="new_discount">
      Enjoy{' '}
      <strong>
        <em>extra discounts</em> up to 12¢/gal
      </strong>{' '}
      through {formattedDate} — only at AtoB Discount Network stations. Prices reflect your upgraded
      discounts.
    </NewFeatureBanner>
  );
}
